// @flow
import React, { Component } from 'react';
import { Grid, Header, Image, Progress } from 'semantic-ui-react';
import { resolvePath, stringConcat } from 'tools'
import { images } from 'constantes';

import './WidgetLinearPercent.scss';

type Props = {
    icon: string,
    title: string,
    percent: string,
    data: any
}

type State = {};

/**
 * WidgetLinearPercent widget
 * @author Tomasz tpr@deltacode.fr
 * @memberof Widgets
 * @extends {React.Component}
 * @description Widget with linear progress bar
 * @return {ReactComponent} WidgetLinearPercent  
 */
class WidgetLinearPercent extends Component<Props,State> {

    render() {
        /**
         * @instance
         * @method render
         * @memberof Widgets.WidgetLinearPercent
         * @return { String } JSX 
         */
        const {data, icon } = this.props;
        const title =  stringConcat(data, this.props.title);
        const percent = resolvePath(data, this.props.percent);
        
        return (
            <Grid className="Widget-linear-percent" verticalAlign='middle'>
                <Grid.Row>
                    <Grid.Column mobile={4} tablet={3} computer={3}>
                        <Image centered size='tiny' src={ images[icon] }/>
                    </Grid.Column>
                    <Grid.Column mobile={4} tablet={3} computer={2}>
                        <Header as='h3'><strong>{title}</strong></Header>
                    </Grid.Column>
                    <Grid.Column mobile={4} tablet={7} computer={8}>
                        <Progress  percent={percent} size='small'/>
                    </Grid.Column>
                    <Grid.Column mobile={4} tablet={2} computer={3}>
                        <Header as='h3'><strong>{percent} %</strong></Header>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export { WidgetLinearPercent }