// @flow
import React from 'react';
import { Metadata } from 'services/metadataService';
import { arrayFirst } from 'tools';

const HistoryContext = React.createContext<any>();

const metaName= 'ernest-history';

type Props = {
    children: any
}

type State = {};

/**
 * HistoryProvider
 * @author Romain rsa@deltacode.fr
 * @memberof Providers
 * @extends {React.Component}
 * @description History provider
 */
class HistoryProvider extends React.Component<Props,State> {

    clearHistory = async (history: any) => {
        /**
         * @instance
         * @async
         * @method clearHistory
         * @memberof Providers.HistoryProvider
         * @param { Object } history History metadata
         * @return { Void }
         */
        try {
            await Metadata.put(history, metaName)
        }
        catch(error) {
            throw error
        }
    }

    getHistory = async () => {
        /**
         * @instance
         * @async
         * @method getHistory
         * @memberof Providers.HistoryProvider
         * @return { Object } History data
         */
        try {
            const response =  await Metadata.get(metaName);
            let history = await response.json();
            if(!history.length){
                const data = { ccn: [], entreprises: []}
                await Metadata.post(data, metaName);
                const response =  await Metadata.get(metaName);
                history = await response.json();
            }            
            return arrayFirst(history)
        }catch(error){
            throw error
        }
    }

    addToHistory = async (scope: string, data: any) => {
        /**
         * @instance
         * @async
         * @method addToHistory
         * @memberof Providers.HistoryProvider
         * @param { String } scope Scope
         * @param { Object } data History metadata
         * @return { Void }
         */
        try {
            const history =  await this.getHistory();
            if(!history.metaValue[ scope].some(item => item.id === data.id)){
                history.metaValue[ scope].unshift( {id: data.id, idcc: data.idcc, title: data.title, description: data.description });
            }
            await Metadata.put(history, metaName);
        }
        catch(error) {
            throw error
        }
    }

    render() {
        /**
         * @instance
         * @method render
         * @memberof Providers.HistoryProvider
         * @return { String } JSX 
         */
       return (
           <HistoryContext.Provider value={{
                getHistory: this.getHistory,
                addToHistory: this.addToHistory,
                clearHistory: this.clearHistory
           }}>
           {this.props.children}
           </HistoryContext.Provider>
       )
   }
}

const HistoryConsumer = HistoryContext.Consumer

export { HistoryProvider, HistoryConsumer }