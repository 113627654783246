// @flow
import React,{ Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Header, Icon, Button, Segment, Message, Container} from 'semantic-ui-react';
import { Helper, Loading } from 'components';
import { messagesCcn, messagesHistory } from "constantes";
import { capitalize, isEmptyObject, setMessage } from "tools";

import './Ccn.scss'; 

type Props = {
    consumers: any,
    location: any,
    match: any,
    history: any
}

type State = {
    loading: boolean,
    error: boolean,
    ccn: any,
    message: any
}

const paths = {
    redirect : "/recherche/ccn"
}

/**
 * Ccn Container
 * @author Tomasz tpr@deltacode.fr
 * @memberof Containers
 * @extends {React.Component}
 * @description Ccn container
 */
class Ccn extends Component<Props,State> {
    
    /**
     * @constructor
     * @param { Object } props Props
     */
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            error: false,
            ccn: {},
            message: {}
        }
    }

    async componentDidMount(){
        /**
         * @instance
         * @async
         * @memberof Containers.Ccn
         * @method componentDidMount
         * @return { Void } 
        */
        if(!this.props.location.state){
            this.props.history.push(paths.redirect)
        }else{
            this.getCcn();
        }
       
    }

    async componentDidUpdate(previousProps:any, previousState:any) {
        /**
         * @instance
         * @method componentDidUpdate
         * @memberof Widgets.WidgetBlock
         * @return { Void } 
         */
        if (this.props.location.state.id !== previousProps.location.state.id) {
            await this.setState({ 
                loading: false
            }); 
            this.getCcn();
        }
    }

     getCcn = async () => {
        const id = this.props.location.state.id;
        const { ccns, history } = this.props.consumers;
        const ccn = await ccns.getCcn(id);      
        try {
            if(ccn){
                await ccns.setLocalCcn(ccn);
                await this.setState({ 
                    loading: true,
                    ccn: ccn
                }); 
            
             }
        }
        catch(error) {
            this.setState({ 
                error: true,
                message: setMessage(messagesCcn, error.status)
            });
        }
        try {
            await history.addToHistory('ccn', { 
                    id: id, 
                    idcc: ccn.idcc,
                    title: ccn.title,
                    description: ccn.description
                }
            );
        }
        catch(error) {
            this.setState({ 
                error: true,
                message: setMessage(messagesHistory, error.status)
            });
        }
     }

    render() {
        /**
         * @instance
         * @method render
         * @memberof Containers.Ccn
         * @return { String } JSX 
        */
        const { loading, error, ccn, message} = this.state;
        const { id } = this.props.match.params;
        return (
            <Fragment>
                <Grid columns={1} className='Ccn page'>
                    <Grid.Row >
                        <Grid.Column>
                            <Segment basic>
                            { !isEmptyObject(message) && <Message color={message.color} content={message.text}/> }
                            </Segment>   
                            <Helper/>
                            <Header as='h1' textAlign='center' color='grey'>IDCC {ccn.idcc} Brochure {ccn.brochure}</Header>
                                {(loading && !error ) ? (
                                    <Fragment>
                                    
                                        <Header as='h2' textAlign='center'>{capitalize(ccn.description)}</Header>
                                        {ccn.attachment && 
                                            <Header as='h4' textAlign='center'>{capitalize(ccn.attachment)}</Header>
                                        }{
                                            ccn.redirectTo && ccn.redirectTo !== "" && <Container textAlign='center'>
                                                <Link className="ui basic button" to={{state: { 
                                                    idcc: ccn.redirectTo.idcc,
                                                    id: ccn.redirectTo.ref,
                                                    scope: 'ccn'
                                                    }, pathname: `/ccn/${ccn.redirectTo.ref}`}} >Redirection vers IDCC {ccn.redirectTo.idcc}</Link>
                                                </Container>
                                        }
                                        {
                                             ccn.redirectFrom && ccn.redirectFrom[0] !== "" && <Container style={{ marginTop: ccn.redirectTo  ? "0.5em" : 0}} textAlign='center'>Redirection depuis IDCC : {ccn.redirectFrom.map(({idcc}) => idcc).join(', ')}</Container>
                                        }
                                        {Object.keys(ccn._structure).map((disposition, index)=>{
                                        
                                            const element = ccn._structure[disposition];
                                            
                                            return (
                                                <Fragment key={index}>
                                                    <Header as='h3' content={element.title} className='color-default'/>
                                                    {Object.keys(element['colleges']).map((college, index)=>{
                                                        let color ='green';
                                                        let condition = false;
                                                        if(ccn.dispositions && ccn.dispositions[disposition]){
                                                            if(ccn.dispositions[disposition][college]){
                                                                color = 'yellow'
                                                                condition = true
                                                            if(ccn.dispositions[disposition][college]['assureurs']){
                                                                color = 'orange'
                                                            }
                                                            if(ccn._metadata && ccn._metadata.dispositions[disposition] && ccn._metadata.dispositions[disposition][college].unusable){
                                                                color = 'red'
                                                            }
                                                        }
                                                        }else{
                                                            if(ccn._metadata && ccn._metadata.dispositions[disposition] && ccn._metadata.dispositions[disposition][college].unusable){
                                                                color = 'red'
                                                            }
                                                        }
                                                    return (
                                                        <Link 
                                                            key={index} 
                                                            className='link medium' 
                                                            data-condition={condition ? 'inactive' : 'active'}
                                                            to={{ pathname:  `/ccn/${id}/detail`, 
                                                            state: { 
                                                                title: ccn.title,
                                                                description: ccn.description,
                                                                disposition: disposition,
                                                                college: college,
                                                                idcc: ccn.idcc,
                                                                id: id,
                                                                scope: 'ccn'
                                                                } 
                                                            }}
                                                            >
                                                            <Icon name='circle' color={color}/>
                                                            {element['colleges'][college]}
                                                            <Icon name='chevron right' color='grey'/>
                                                        </Link>
                                                    )
                                                    })}
                                                </Fragment>
                                            )   
                                        })}
                                        <Segment basic textAlign='center'>
                                            <Link to={{ pathname: `/entreprises/${id}`, state: {
                                                id: id,
                                                idcc: ccn.idcc,
                                                title: ccn.title,
                                                description: ccn.description,
                                                codes: ccn.ape
                                                } 
                                            }}>
                                            <Button className='btn default' size='large' >
                                                <Button.Content>Voir les entreprises</Button.Content>
                                            </Button>
                                            </Link>
                                        </Segment>
                                 
                                    </Fragment>
                                    ) : (
                                        <Fragment>{ !error && <Loading title={`Chargement`}/>} </Fragment>
                                    )}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Fragment>
        );
    }
}

export { Ccn };
