// @flow
import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import { Grid, Header, Transition, Segment, Button, Icon, Message } from 'semantic-ui-react';
import { Wrapper,  Loading } from "components";
import { messagesSettings } from "constantes";
import { isEmptyObject, setMessage } from "tools";
import './Settings.scss'; 

type Props = {
    consumers: any
};

type State = {
    loading: boolean,
    visible: boolean,
    error: boolean,
    user: any,
    message: any
};

/**
 * Settings
 * @author Tomasz tpr@deltacode.fr
 * @memberof Containers
 * @extends {React.Component}
 * @description Settings container
 */
class Settings extends Component<Props, State> {
  
    /**
     * @constructor
     * @param { Object } props Props
    */
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            visible: false,
            error: false,
            user: {},
            message: {}
        }
    }
  
    async componentDidMount(){
        /**
         * @instance
         * @async
         * @memberof Containers.Settings
         * @method componentDidMount
         * @return { Void } 
        */
        await this.setState({ 
            visible: true
        });
        try{
            let response;
            const { connection, account, user } = this.props.consumers;
            if(connection.owner){
                response = await account.getAccount();
            }else{
                response = await user.getUser();
            }
            await this.setState({ 
                loading: true,
                user: response
            });
        } catch(error) {

            await this.setState({
                loading: true,
                error: true,
                message: setMessage(messagesSettings, error.status)
            });
        }
    }

    render() {
        /**
         * @instance
         * @method render
         * @memberof Containers.Settings
         * @return { String } JSX 
        */
        const { loading, visible, error, user, message } = this.state
        const { owner, logout} = this.props.consumers.connection;
        
        return (
            <Grid className="Settings page bg-gradient" >
                <Grid.Row >
                    <Grid.Column>
                         <Transition visible={visible} animation='fade up' duration={500}>
                            <Header as='h2' textAlign='center' className='lead'>
                                Vous êtes connecté  <br/> en tant que {user.firstName} {user.lastName}
                            </Header>
                        </Transition>
                        {loading ? (
                            <Fragment>
                                { !isEmptyObject(message) && <Segment><Message color={message.color} content={message.text} /></Segment> }
                                {!error && 
                                    <Fragment>
                                        {owner ?
                                        <Link className="link medium center" to="/parametres/compte">
                                            <Icon circular name='user'/>
                                            <Wrapper name="link-content">Mon compte</Wrapper>
                                        </Link>
                                        : <Link className="link medium center" to="/parametres/utilisateur">
                                            <Icon circular name='user'/>
                                            <Wrapper name="link-content">Mon compte</Wrapper>
                                        </Link>
                                        }
                                        {owner &&
                                        <Link className="link medium center" to="/parametres/utilisateurs">
                                            <Icon circular name='users'/>
                                            <Wrapper name="link-content">Utilisateurs</Wrapper>
                                        </Link>}
                                        <Link className="link medium center" to="/parametres/contact">
                                            <Icon circular  name='mail'/>
                                            <Wrapper name="link-content">Contact</Wrapper>
                                        </Link>
                                    </Fragment>
                                }
                            </Fragment>
                        ) : (
                            <Wrapper name="block">
                                <Loading title="Chargement" />
                            </Wrapper>
                        )}
                        <Segment basic textAlign='center'>
                            <Button className='btn default' onClick={logout}  size='large' animated="fade">
                            <Button.Content visible>Déconnexion</Button.Content>
                            <Button.Content hidden>
                                A très bientôt
                            </Button.Content>
                            </Button>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export { Settings }
