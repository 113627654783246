// @flow
import { LocalStorage } from './storageService';
import { checkStatus, checkError } from 'tools';

/**
 * User service
 * @author Tomasz tpr@deltacode.fr
 * @memberof Services
 * @description User service
 */
class User  {

    static entrypoints:any = {
        firstConnection : 'users/firstConnection',
        getUser : 'users',
        getUsers : 'users',
        addUser: 'users',
        deleteUser: 'users',
        updateUser: 'users'
    }
    
    static url: any = process.env.REACT_APP_API_URL;

    static getUser = async (uid:string) => {
        /**
         * Get user
         * @async 
         * @method getUser
         * @memberof Services.User
         * @param uid user ID to get
         * @return { Object } Response user
         */
        try {
            const token = await LocalStorage.getItem('token') || '{}';
            const response = await fetch(`${this.url}/${this.entrypoints.getUser}/${uid}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                mode: 'cors'
            });
            return checkStatus(response);
        } catch(error) {
            throw checkError(error);
      }
    }

    static getUsers = async () => {
        /**
         * Get users
         *
         * @async
         * @method getUsers
         * @memberof Services.User
         * @return { Array } Response users
        */
        try {
            const token = await LocalStorage.getItem('token') || '{}';
            const response = await fetch(`${this.url}/${this.entrypoints.getUsers}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                mode: 'cors'
            });
            return checkStatus(response);
        } catch(error) {
            throw checkError(error);
      }
    }

    static addUser = async (data:any) => {
        /**
         * Add user
         *
         * @async
         * @method addUser
         * @memberof Services.User
         * @param data datas of user to create
         * @return { String } Response user
        */
        try {
            const token = await LocalStorage.getItem('token') || '{}';
            const response = await fetch(`${this.url}/${this.entrypoints.addUser}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                mode: 'cors',
                body: JSON.stringify(data)
            });
            return checkStatus(response);
        } catch(error) {
            throw checkError(error);
      }
    }

    static deleteUser = async (uid:string) => {
        /**
         * Delete user
         *
         * @async
         * @method deleteUser
         * @memberof Services.User
         * @param uid User ID to delete
         * @return { String } Response user
        */
        try {
            const token = await LocalStorage.getItem('token') || '{}';
            const response = await fetch(`${this.url}/${this.entrypoints.deleteUser}/${uid}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                mode: 'cors'
            });
            return checkStatus(response);
        } catch(error) {
            throw checkError(error);
      }
    }

    static updateUser = async (data:any) => {
        /**
         * Update user
         *
         * @async
         * @method updateUser
         * @memberof Services.User
         * @param data datas of users to update
         * @return { String } Response user
        */
        try {
            const token = await LocalStorage.getItem('token') || '{}';
            const response = await fetch(`${this.url}/${this.entrypoints.updateUser}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                mode: 'cors',
                body: JSON.stringify(data)
            });
            return checkStatus(response);
        } catch(error) {
            throw checkError(error);
      }
    }

    static firstConnection = async (data:any) => {
        /**
         * First Connection
         *
         * @async
         * @method firstConnection
         * @memberof Services.User
         * @param { Object } data User data
         * @return { String } Response token
        */
        try {
            const token = await LocalStorage.getItem('token') || '{}';
            const response = await fetch(`${this.url}/${this.entrypoints.firstConnection}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                mode: 'cors',
                body: JSON.stringify(data)
            });
            return checkStatus(response);
        } catch(error) {
            throw checkError(error);
      }
    }

}
export  { User };