// @flow
/**
 * Tools Namespace
 * @namespace Tools
*/

/**
 * @author Tomasz tpr@deltacode.fr
 * @memberof Tools
 * @function
 * @name resolvePath
 * @description Convert dots operator to array access
 * @return {string} path  
 */
const resolvePath = (obj: any, path: any) => 
  path
    .replace(/\[|\]\.?/g, '.')
    .split('.')
    .filter(s => s)
    .reduce((acc, val) => acc && acc[val], obj);

/**
 * @author Tomasz tpr@deltacode.fr
 * @memberof Tools
 * @function
 * @name stringConcat
 * @description String concat
 * @return {string} result  
 */
const stringConcat = (obj: any, arr: any) =>{
    let result = '';
     arr.map((element) => {
       return result =  result.concat(' ', element.constructor === Object ? resolvePath(obj, element.path) : element)
    })
    return result;
}

/**
 * @author Tomasz tpr@deltacode.fr
 * @memberof Tools
 * @function
 * @name capitalize
 * @description Capitalize first letter
 * @return {string} str  
 */
const capitalize = (str: string) => {
  return  str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * @author Tomasz tpr@deltacode.fr
 * @memberof Tools
 * @function
 * @name getCurrentPosition
 * @description Get browser position 
 * @return {object} cords  
 */
const getCurrentPosition = (options:any = {}):Promise<any> => {
  return new Promise((resolve:Function, reject:Function) => 
    navigator.geolocation.getCurrentPosition(resolve, reject, options)
  );
}

/**
 * @author Tomasz tpr@deltacode.fr
 * @memberof Tools
 * @function
 * @name isObject
 * @description Check if object
 * @return {boolean} boolean  
 */
const isObject = (obj: any)=> {
  return obj instanceof Object; 
}

/**
 * @author Tomasz tpr@deltacode.fr
 * @memberof Tools
 * @function
 * @name isEmptyObject
 * @description Check whether an object is empty
 * @return {boolean} boolean  
 */
 const isEmptyObject = (obj: any)=> {
  return Object.keys(obj).length === 0
}

/**
 * @author Tomasz tpr@deltacode.fr
 * @memberof Tools
 * @function
 * @name arrayFirst
 * @description Get first element from array
 * @return {any} First element   
 */
const arrayFirst = (array: any) =>{
    const [first] = array
    return first
}

/**
 * @author Tomasz tpr@deltacode.fr
 * @memberof Tools
 * @function
 * @name setMessage
 * @description Set message values
 * @return {object} object message values
 */
const setMessage = (message: any, status: number) => {
    return {
        text: message[status], 
        color: status === 200 ? 'green' : 'red'
    } 
}

/**
 * @author Tomasz tpr@deltacode.fr
 * @memberof Tools
 * @function
 * @name checkStatus
 * @description Check status response
 * @return {object} object response
 */
const checkStatus = (response: any) =>{
    if(response.status !== 200){
        const error = { status: response.status };
        throw error;
    }
    return response
}

const getTimstamp = () => {
  return Math.floor(new Date().getTime()/1000)
}

/**
 * @author Tomasz tpr@deltacode.fr
 * @memberof Tools
 * @function
 * @name checkError
 * @description Check error response
 * @return {object} object response
 */
const checkError = (error: any) => {
  if(!error.status ){
      error = { status: 503 };
  }
  return error;
}

/**
 * @author Tomasz tpr@deltacode.fr
 * @memberof Tools
 * @function
 * @name removeAccents
 * @description Remove accents from strings
 * @return {string} string
 */
const removeAccents = (string: string) => {
  const accents =
    "ÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž";
  const accentsOut =
    "AAAAAAaaaaaaBOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";
  return string
    .split("")
    .map((item, index) => {
      const accentIndex = accents.indexOf(item);
      return accentIndex !== -1 ? accentsOut[accentIndex] : item;
    })
    .join("");
}

/**
 * @author Tomasz tpr@deltacode.fr
 * @memberof Tools
 * @function
 * @name pad
 * @description Return current string with another string until the resulting string reaches the given length
 * @return {string} string
 */
const pad = (width: number, string: string, padding: string) => { 
  return (width <= string.length ) ? string : pad(width, padding + string, padding);
}

/**
 * @author Tomasz tpr@deltacode.fr
 * @memberof Tools
 * @function
 * @name isEmptyEveryObjects
 * @description Check all empty objects in array
 * @return {number} Array length
 */
 const isEmptyEveryObjects = (patternArray: any, filterArray:any) => { 
  if(!filterArray) return false
    return  patternArray
        .filter((item:any, index: number) => 
            filterArray[item] && !Object.keys(filterArray[item]).every((k) => filterArray[item][k] === "")
        ).length
}

/**
 * @author Tomasz tpr@deltacode.fr
 * @memberof Tools
 * @function
 * @name numberFormat
 * @description Format a number as currency 
 * @return {string} Price
 */
const numberFormat = (value: number) => {
  return value ? new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0, minimumFractionDigits: 0  }).format(value) : 'inconnu' 
}

/**
 * @author Tomasz tpr@deltacode.fr
 * @memberof Tools
 * @function
 * @name getKeyFiguresYears
 * @description Format key figures by year
 * @return {object} Key figures 
 */
const getKeyFiguresYears = () => {
  return { 
    n2: new Date().getFullYear() - 2, 
    n3: new Date().getFullYear() - 3, 
    n4: new Date().getFullYear() - 4
  };
}

/**
 * @author Tomasz tpr@deltacode.fr
 * @memberof Tools
 * @function
 * @name chunks
 * @description Chunks array by size
 * @return {array} Chunked array 
 */
const chunks = (array:any, size: number) => {
  let results = [];
  while (array.length) {
    results.push(array.splice(0, size));
  }
  return results;
};

/**
 * @author Tomasz tpr@deltacode.fr
 * @memberof Tools
 * @function
 * @name formatAddress
 * @description Format address
 * @return {string} Address  
 */
const formatAddress = (address: any) => {
  return Object.values( {
    ...(address.complementAdresse && {complementAdresse : address.complementAdresse}),
    ...(address.numeroVoie && {numeroVoie : address.numeroVoie}),
    ...(address.indiceRepetition && {indiceRepetition : address.indiceRepetition}),
    ...(address.typeVoie && {typeVoie : address.typeVoie}),
    ...(address.libelleVoie && {libelleVoie : address.libelleVoie}),
    ...(address.distributionSpeciale && {distributionSpeciale : address.distributionSpeciale}),
    ...(address.codePostal && {codePostal : address.codePostal}),
    ...(address.libelleCommune && {libelleCommune : address.libelleCommune}),
    ...(address.codePaysEtranger && {codePaysEtranger : address.codePaysEtranger}),
    ...(address.libelleCommuneEtranger && {libelleCommuneEtranger : address.libelleCommuneEtranger}),
    ...(address.libellePaysEtranger && {libellePaysEtranger : address.libellePaysEtranger}),
  }).join(' ');
}

/**
 * @author Hugo hle@deltacode.fr
 * @memberof Tools
 * @function
 * @name getPaginationArray
 * @description Get an array to display inside a pagination system
 * @return {array} array to display in the page  
 */
const getPaginationArray = (originArray: any= [], activePage: number = 1, itemsPerPage: number = 20) => {
  const maxIndex = activePage * itemsPerPage - 1;
  const minIndex = activePage * itemsPerPage - itemsPerPage;
  const paginationArray = originArray.filter((value, index) => {
    if (index >= minIndex && index <= maxIndex) {
      return true;
    } else {
      return false;
    }
  });
  return paginationArray;
}

export {
    resolvePath,
    stringConcat,
    capitalize,
    getCurrentPosition,
    isObject,
    isEmptyObject,
    arrayFirst,
    setMessage,
    checkStatus,
    getTimstamp,
    checkError,
    removeAccents,
    pad,  
    isEmptyEveryObjects,
    numberFormat,
    getKeyFiguresYears,
    chunks,
    formatAddress,
    getPaginationArray
}