// @flow
import React, { Component } from "react";
import { PasswordUpdateForm } from 'forms';

type Props = {
    consumers: any,
    match: any
}

type State = {}

/**
 * PasswordUpdate
 * @author Tomasz tpr@deltacode.fr
 * @memberof Containers
 * @extends {React.Component}
 * @description PasswordUpdate container
 */

class PasswordUpdate extends Component<Props, State> {

    render() {
        /**
         * @instance
         * @method render
         * @memberof Containers.PasswordUpdate
         * @return { String } JSX 
        */
        const { consumers } = this.props;
        const { hash } = this.props.match.params
        return (
            <PasswordUpdateForm consumers={consumers} hash={hash}/>
        );
    }
}

export { PasswordUpdate };