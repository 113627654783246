// @flow
import React, { Component, Fragment } from 'react';
import { Grid, Image } from 'semantic-ui-react';
import { Wrapper, Loading } from "components";
import { stringConcat } from 'tools'
import {images} from 'constantes';

import './WidgetGrid.scss';

type Props = {
    responsive: any,
    vertical: boolean,
    styles: any,
    image: any,
    content: any,
    content: any,
    data: any
}

type State = {};

/**
 * WidgetGrid widget
 * @author Tomasz tpr@deltacode.fr
 * @memberof Widgets
 * @extends {React.Component}
 * @description WidgetGrid
 * @return {ReactComponent} WidgetGrid  
 */
class WidgetGrid extends Component<Props,State> {
    
    render() {
        /**
         * @instance
         * @method render
         * @memberof Widgets.WidgetGrid
         * @return { String } JSX 
         */
        const {responsive, content, data, vertical, image, styles } = this.props;
        const align = vertical ? '  ' : 'center';
        const centered = vertical; 
        const display =  vertical ? 'block' : 'inline-block';
        const img = (image !== undefined) ? image : {};
        const css = (styles !== undefined) ? styles : {};
        const css_title = (css.title !== undefined) ? css.title : {};
        const css_header = (css.header !== undefined)  ? css.header : {};
        const css_paragraph = (css.paragraph !== undefined)  ? css.paragraph : {};
        const img_size = (img.size !== undefined)  ? img.size : 'tiny';

        return (
            
            <Grid>  
             {data && content ? (
                <Grid.Row className="Widget-grid" >
                    {content.map((element, index)=> {
                        
                        const header = (element.header) ? stringConcat(data, element.header) : '';
                        const title = (element.title) ? stringConcat(data, element.title) : '';
                        const paragraph = (element.paragraph) ? stringConcat(data, element.paragraph) : '';

                        return (
                            <Grid.Column
                                textAlign='center'
                                key={index}
                                mobile={responsive.mobile}
                                tablet={responsive.tablet}
                                computer={responsive.computer}
                            >
                                <Fragment>
                                    <Image src={images[element.icon]} size={img_size} centered={centered} verticalAlign='middle' style={{...{display:display}}}/>
                                    <Wrapper name={"title "+align}  style={{...css_title, ...{display:display}}}> {title}</Wrapper>
                                    <Wrapper name={"header "+align}  style={{...css_header, ...{display:display}}}>
                                        {header}
                                    </Wrapper>
                                    <Wrapper name={"description "+align} style={{...css_paragraph, ...{display:display}}}>
                                        {paragraph}
                                    </Wrapper>
                                </Fragment>     
                            </Grid.Column>
                        )
                    })}
                </Grid.Row>
                ) : (
                    <Loading title="Chargement"/>
                )}
            </Grid>
            
        );
    }
}

export { WidgetGrid }