// @flow
import React from 'react';
import { User } from 'services/userService';
import { LocalStorage } from 'services/storageService';
import jwt from 'jsonwebtoken';

const UserContext = React.createContext<any>();

type Props = {
    children:any
}

type State = {};

/**
 * UserProvider
 * @author Tomasz tpr@deltacode.fr
 * @memberof Providers
 * @extends {React.Component}
 * @description User provider
 */
class UserProvider extends React.Component<Props,State> {

    getUser = async () => {
        /**
         * @instance
         * @async
         * @method getUser
         * @memberof Providers.UserProvider
         * @return { Object } User
         */
        try {
            const token = await LocalStorage.getItem('token');
            const decoded = jwt.decode(String(token));
            const response = await User.getUser(decoded.uid);
            const payload = await response.json();
            return payload
        }
        catch(error) {
            throw error
        }
    }

    addUser = async (data:any) => {
        /**
         * @instance
         * @async
         * @method addUser
         * @memberof Providers.UserProvider
         * @param { Object } data User data
         * @return { Array } User list
         */
        try {
           const response = await User.addUser(data);
           return response;
        }
        catch(error) {
           throw error
        }
    }

    deleteUser = async (uid: string) => {
        /**
         * @instance
         * @async
         * @method deleteUser
         * @memberof Providers.UserProvider
         * @param { String } uid User uid
         * @return { String } Deleted user message
         */
        try {
            const response = await User.deleteUser(uid);
            return response;
        }
        catch(error) {
            throw error
        }
    }

    updateUser = async (data:any) => {
        /**
         * @instance
         * @async
         * @method updateUser
         * @memberof Providers.UserProvider
         * @param { Object } data User data
         * @return { String } Updated user message 
         */
        try {
            const response = await User.updateUser(data);
            return response;
        }
        catch(error) {
            throw error
        }
    }


    getUsers = async () => {
        /**
         * @instance
         * @async
         * @method getUsers
         * @memberof Providers.UserProvider
         * @return { Array } User list
         */
         try {
            const response = await User.getUsers();
            const payload = await response.json();
            return payload;
        }
        catch(error) {
            throw error
        }
    }

    render() {
        /**
         * @instance
         * @method render
         * @memberof Providers.UserProvider
         * @return { String } JSX 
         */
        return (
            <UserContext.Provider 
                value={{
                    ...this.state,
                    getUser: this.getUser,
                    getUsers: this.getUsers,
                    addUser: this.addUser,
                    deleteUser: this.deleteUser,
                    updateUser: this.updateUser
                }}>
                {this.props.children}
            </UserContext.Provider>
        )
    }
}

const UserConsumer = UserContext.Consumer

export { UserProvider, UserConsumer }