const messagesForm = {
    required: "Ce champ est obligatoire",
    isEmail: "Vous devez renseigner une adresse mail valide",
    isZipCode: "Veuillez rentrer un code postal valide",
    isZipCodeFull: "Votre code postal n'est pas complet",
    isZipCodeCorrect: "Votre code postal n'est pas correct",
    isPhone: "Veuillez rentrer un numéro de téléphone valide",
    isPhoneFull: "Votre numéro de téléphone n'est pas complet",
    isPhoneCorrect: "Votre numéro de téléphone n'est pas correct",
    isPassword: "Votre mot de passe doit contenir un minimum de 8 caractères dont une majuscule, une minuscule et un chiffre",
    differentPassword: "Le nouveau mot de passe doit être différent",
    matchPassword: "Les deux mots de passes doivent être identiques"
}

const messagesFinder = {
    400: "Une erreur s'est produite! Revenez un peu plus tard, merci 😊",
}

const messagesforgetPassword = {
    200: "On vous a envoyé un e-mail avec les instructions pour modifier votre mot de passe. 😃",
    404: "Oups, vous avez saisi une adresse mail inconnue. 😕",
    503: "Une erreur s'est produite! Revenez un peu plus tard, merci 😊",
}

const messagesHistory = {
    503: "Une erreur s'est produite! Revenez un peu plus tard, merci 😊",
}

const messagesFavorites= {
    503: "Une erreur s'est produite! Revenez un peu plus tard, merci 😊"
}

const messagesCcn = {
    400: "Une erreur s'est produite! Revenez un peu plus tard, merci 😊",
    403: "Une erreur s'est produite! Revenez un peu plus tard, merci 😊",
}

const messagesSignIn = {
    400: "Vous avez oublié quelque chose ! On ne peut donc pas vous connecter. 🙃",
    403: "Vous n'êtes pas autorisé à accéder à cette application",
    404: "Vous faites erreur, merci de vérifier votre adresse mail et votre mot de passe. 😇",
    503: "Une erreur s'est produite! Revenez un peu plus tard, merci 😊",
} 

const messagesSettings = {
    401: "Hop, c'est interdit ici ! On ne vous permet pas l’accès 🤐",
    404: "Une erreur s'est produite! Revenez un peu plus tard, merci 😊",
    503: "Une erreur s'est produite! Revenez un peu plus tard, merci 😊",
}

const messagesMailSend = {
    200: "Reçu 5/5 ! 👂 On vous répondra dès que possible !",
    400: "Il manque des informations ! Votre message n'a pas pu être envoyé à FASST. 🙃",
    500: "Une erreur s'est produite! Revenez un peu plus tard, merci 😊",
    503: "Une erreur s'est produite! Revenez un peu plus tard, merci 😊"
} 

const messagesCompanies = {
    1: "Vérifier que votre navigateur ou/ et système autorise la géolocalisation.",
    404: "Aucun établissement trouvé",
    502: "Ernest se repose, il n'est pas disponible pour le moment ! Revenez un peu plus tard, merci 😊",
    504: "Nombre d'entreprises trop important, veuillez modifier votre position (adresse ou rayon) ou contactez-nous pour une extraction personnalisée "
} 

const messageUsers = {
    401: "Hop, c'est interdit ici ! On ne vous permet pas l’accès 🤐",
    500: "Une erreur s'est produite! Revenez un peu plus tard, merci 😊",
    503: "Une erreur s'est produite! Revenez un peu plus tard, merci 😊",
}

const messagesUserAdd = {
    200: "Votre utilisateur à bien été ajouté. On vient de lui envoyer un e-mail afin de se valider ! 🥳",
    400: "Vous avez oublié quelque chose ! On ne peut donc pas créer votre utilisateur. 🙃",
    401: "Hop, c'est interdit ici ! On ne vous permet pas l’accès 🤐",
    409: "Cette adresse mail est déjà connue! 🧐",
    500: "Une erreur s'est produite! Revenez un peu plus tard, merci 😊",
    503: "Une erreur s'est produite! Revenez un peu plus tard, merci 😊"
} 

const messagesUserDelete = {
    200: "L'utilisateur a bien été supprimé. 😭",
    401: "Hop, c'est interdit ici ! On ne vous permet pas l’accès 🤐",
    500: "Une erreur s'est produite! Revenez un peu plus tard, merci 😊",
    503: "Une erreur s'est produite! Revenez un peu plus tard, merci 😊"
} 

const messagesAccountGet = {
    401: "Hop, c'est interdit ici ! On ne vous permet pas l’accès 🤐",
    404: "On ne trouve pas votre nom d'utilisateur, veuillez réessayer 😊",
    503: "Une erreur s'est produite! Revenez un peu plus tard, merci 😊"
}

const messagesAccountForm = {
    200: "On a bien mis vos informations à jour. 😊",
    400: "Vous avez oublié quelque chose ! On ne peut donc pas mettre à jourvotre compte. 🙃",
    401: "Hop, c'est interdit ici ! On ne vous permet pas l’accès 🤐",
    403: "Votre ancien mot de passe n'est pas correct ! Votre mot de passe n'a pas pu être mis à jour. 🙃",
    404: "Oh mince, une erreur est survenue. On n'a pas pu mettre à jour vos informations. 🙃",
    409: "Cette adresse mail est déjà connue ! 🧐",
    500: "Une erreur s'est produite! Revenez un peu plus tard, merci 😊",
    503: "Une erreur s'est produite! Revenez un peu plus tard, merci 😊"
} 

const messagesUser = {
    401: "Hop, c'est interdit ici ! On ne vous permet pas l’accès 🤐",
    500: "Vous faites erreur, merci de vérifier votre adresse mail et votre mot de passe. 😇",
    503: "Une erreur s'est produite! Revenez un peu plus tard, merci 😊",
} 

const messagesUserForm = {
    200: "Super, On a bien mis vos informations à jour 🥳",
    400: "Vous avez oublié quelque chose ! On ne peut donc pas mettre à jour votre compte. 🙃",
    401: "Hop, c'est interdit ici ! On ne vous permet pas l’accès 🤐",
    403: "Votre ancien mot de passe n'est pas correct ! Votre mot de passe n'a pas pu être mis à jour. 🙃",
    409: "Cette adresse mail est déjà connue ! 🧐",
    503: "Une erreur s'est produite! Revenez un peu plus tard, merci 😊",
} 

const messagesPasswordUpdate = {
    200: "Super, On a bien mis vos informations à jour 🥳",
    400: "Oups, vous avez saisi un mot de passe invalide. 😕",
    404: "On ne trouve pas votre nom d'utilisateur, veuillez réessayer 😊",
    500: "Une erreur s'est produite! Revenez un peu plus tard, merci 😊",
    503: "Une erreur s'est produite! Revenez un peu plus tard, merci 😊"
} 

const messagesUserFirstConnection = {
    200: "On a bien mis vos informations à jour. 😊",
    400: "Vous avez oublié quelque chose ! On ne peut donc pas mettre à jour votre compte. 🙃",
    401: "Hop, c'est interdit ici ! On ne vous permet pas l’accès 🤐",
    500: "Une erreur s'est produite! Revenez un peu plus tard, merci 😊",
    503: "Une erreur s'est produite! Revenez un peu plus tard, merci 😊"
} 

export { 
    messagesForm,
    messagesFinder,
    messagesHistory,
    messagesFavorites,
    messagesCcn, 
    messagesSignIn, 
    messagesSettings, 
    messagesMailSend, 
    messagesCompanies,
    messageUsers,
    messagesUserAdd,
    messagesAccountGet,
    messagesUserDelete,
    messagesAccountForm,
    messagesUser,
    messagesUserForm,
    messagesUserFirstConnection,
    messagesforgetPassword,
    messagesPasswordUpdate
};