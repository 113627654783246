// @flow
import React, { Component } from 'react';
import { Header, Image } from 'semantic-ui-react';
import { Wrapper } from "components";
import { resolvePath, stringConcat } from 'tools';
import { iconCalendrier } from 'constantes';

import './WidgetCalendar.scss';

type Props = {
    align: string,
    title: string,
    date: string,
    paragraph: any,
    data: any
}

type State = {};

/**
 * WidgetCalendar widget
 * @author Tomasz tpr@deltacode.fr
 * @memberof Widgets
 * @extends {React.Component}
 * @description Widget with calendar
 * @return {ReactComponent} WidgetCalendar  
 */
class WidgetCalendar extends Component<Props,State>  {

    render() {
        /**
         * @instance
         * @method render
         * @memberof Widgets.WidgetCalendar
         * @return { String } JSX 
         */
        const { data } = this.props
        const title = resolvePath(data, this.props.title);
        const date = resolvePath(data, this.props.date);
        const paragraph = stringConcat(data, this.props.paragraph);

        return (
        <Wrapper className='Widget-calendar' style={{ position: 'relative' }}>
            <Header size='medium' className='color-default' content={title}/>
            <Wrapper className='content' style={{  position: 'relative', textAlign: 'center' }}>
                <Wrapper style={{ fontSize: '1.8rem', fontWeight: 600, position: 'absolute', top: '50%', width: '100%'}} className='color-default'>{date ? date : ''}</Wrapper>
                <Image style={{ marginTop: '1.5em', height: '6.5em'}} src={iconCalendrier} centered />  
            </Wrapper>
            <Wrapper style={{ marginTop: '1.2em', fontSize: '1.2rem' }}>{paragraph}</Wrapper>
        </Wrapper>
        );
    }
}

export { WidgetCalendar }