// @flow
import { LocalStorage } from './storageService';
import { checkStatus, checkError } from 'tools';

/**
 * Review service
 * @author Tomasz tpr@deltacode.fr
 * @memberof Services
 * @description Review service
 */
class Review {
    
    static url:any = process.env.REACT_APP_CCN_API
    static path:any = process.env.REACT_APP_CCN_API_PATH_REVIEW
    
    static entrypoints:any = {
        search : `${this.path}/search`
    }

    static get = async (query:string) => {
        /**
         * Get
         *
         * @async
         * @method get
         * @memberof Services.Review
         * @param { String } query comma-separated values and timestamp
         * @return { Object } Reviews
        */
        try {
            const token = await LocalStorage.getItem('token') || '{}';
            const response = await fetch(`${this.url}/${this.entrypoints.search}?id=ref&${query}`, {
            method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                mode: 'cors'
            });
            return checkStatus(response);
        } catch(error) {
            throw checkError(error);
        }
    }
}
export  { Review };