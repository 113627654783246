// @flow
import React, { Component } from "react";
import {
  Transition,
  Header,
  Grid
} from "semantic-ui-react";
import { 
    FirstConnectionUserForm
} from "forms";
import { Wrapper, Loading } from "components";

import "./FirstConnection.scss";

type Props = {
    consumers: any
};

type State = {
    loading: boolean,
    visible: boolean
};

/**
 * FirstConnection Container
 * @author Hugo hle@deltacode.fr
 * @memberof Containers
 * @extends {React.Component}
 * @description FirstConnection container
 */
class FirstConnection extends Component<Props, State> {

    /**
     * @constructor
     * @param { Object } props Props
    */
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            visible: false
        }
    }

    async componentDidMount() {
        /**
         * @instance
         * @async
         * @memberof Containers.FirstConnection
         * @method componentDidMount
         * @return { Void } 
        */
        await this.setState({ loading: true, visible: true });
    }
    
    render() {
        /**
         * @instance
         * @method render
         * @memberof Containers.FirstConnection
         * @return { String } JSX 
        */
        const { loading, visible } = this.state;
        const consumers = this.props.consumers;
        return (
            <Grid className="FirstConnection page bg-gradient">
                <Grid.Row>
                    <Grid.Column>
                        <Transition visible={visible} animation="fade up" duration={500}>
                            <Header as="h2" textAlign="center" className="lead">
                            Informations
                            </Header>
                        </Transition>
                        {loading ? (
                            <Wrapper name="block">
                                { <FirstConnectionUserForm  consumers={ consumers } />}
                            </Wrapper>
                        ) : (
                            <Wrapper name="block">
                                <Loading title="Chargement" />
                            </Wrapper>
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export { FirstConnection };