// @flow
import React, { Component, Fragment } from "react";
import { Header, Segment, Button, Message } from "semantic-ui-react";
import { Form, Input, TextArea } from "semantic-ui-react-form-validator";
import { messagesForm, messagesMailSend } from "constantes";
import { isEmptyObject, setMessage } from "tools";

import "./ContactForm.scss";

type Props = {
    consumers: any
};

type State = {
    awaiting: boolean,
    subject: string,
    content: string,
    message: any
};

/**
 * ContactForm
 * @author Hugo hle@deltacode.fr
 * @memberof Forms
 * @extends {React.Component}
 * @description Contact form
 */

class ContactForm extends Component<Props,State> {

    state = {
        awaiting: false,
        subject: "",
        content: "",
        message: {}
    }

    handleInputChange = (event:SyntheticInputEvent<>, { name, value }:any) => {
        /**
         * @instance
         * @memberof Forms.ContactForm
         * @method handleInputChange
         * @params { Object } event Event
         * @params { String } name Form name
         * @params { String } name Form value
         * @return { Void } 
        */
        this.setState({ [name]: value });
    };

    handleSubmit = async () => {
        /**
         * @instance
         * @async
         * @memberof Forms.ContactForm
         * @method handleSubmit
         * @return { Void } 
        */
        await this.setState({ awaiting: true });
        let status = null;
        try {
            const { mail } = this.props.consumers;
            await mail.sendMail(this.state.subject, this.state.content);
            status = 200
        } catch(error) {
            status = error.status
        }

        await this.setState({ 
            awaiting: false,
            subject: '',
            content: '',
            message: setMessage(messagesMailSend, status)
        });
    };

    render() {  
        /**
         * @instance
         * @method render
         * @memberof Containers.ContactForm
         * @return { String } JSX 
         */
        const {
            awaiting,
            subject,
            content,
            message
        } = this.state;

        return (
            <Fragment>
                <Header
                    className="color-default"
                    textAlign="center"
                    as="h3"
                    content="Contactez-nous"
                />
                { !isEmptyObject(message) && <Message color={message.color} content={message.text}/> }
                <Form className="ui form ContactForm" onSubmit={this.handleSubmit}>
                    <Input
                        label="Sujet"
                        placeholder="Votre sujet"
                        width={16}
                        name="subject"
                        value={subject}
                        validators={["required"]}
                        errorMessages={[messagesForm.required]}
                        onChange={this.handleInputChange}
                    />
                    <TextArea
                        label="Message"
                        placeholder="Votre message"
                        width={16}
                        rows={7}
                        name="content"
                        value={content}
                        validators={["required"]}
                        errorMessages={[messagesForm.required]}
                        onChange={this.handleInputChange}
                    />
                    <Segment basic textAlign="center">
                        <Button
                            disabled={awaiting ? true : false}
                            loading={awaiting ? true : false}
                            content="Envoyer"
                            type="submit"
                            className="btn default"
                            size="large"
                        />
                    </Segment>
                </Form>
            </Fragment>
        );
    }
}

export { ContactForm };
