// @flow
import { checkStatus, checkError } from 'tools';

/**
 * Auth service
 * @author Tomasz tpr@deltacode.fr
 * @memberof Services
 * @description Auth service
 */
class Auth {

    static entrypoints:any = {
        auth : 'auth'
    }
    
    static url: any = process.env.REACT_APP_API_URL;

    static getToken = async (email:string, password:string) => {
        /**
         * Get token
         *
         * @async
         * @method getToken
         * @memberof Services.Auth
         * @param { String } email User email
         * @param { String } password User password
         * @return { String } Valid token
        */
        try {
            const response = await fetch(`${this.url}/${this.entrypoints.auth}`, {
                method: 'POST',
                headers: {
                        'Content-Type': 'application/json',
                },
                mode: 'cors',
                body: JSON.stringify({ email: email, password: password }),
            });
            return checkStatus(response);
        } catch(error) {
            throw checkError(error)
      }
    }

    static authenticate = async (cid:string) => {
        /**
         * Activate user
         *
         * @async
         * @method Authenticate
         * @memberof Services.Activate
         * @param { String } hash Activate hash
         * @return { String } Activate message
        */
        try {
            const response = await fetch(`${this.url}/${this.entrypoints.auth}/usecid/${cid}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                mode: 'cors',
            });
            return checkStatus(response);
        } catch(error) {
            throw checkError(error);
        }
    }
}
export  { Auth };