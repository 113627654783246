// @flow
import React, { Component } from 'react';
import { List, Image } from 'semantic-ui-react';
import { resolvePath, stringConcat } from 'tools';
import {images} from 'constantes';

import './WidgetList.scss';

type Props = {
    image: any,
    styles: any,
    content: any,
    data: any
}

type State = {};

/**
 * WidgetList widget
 * @author Tomasz tpr@deltacode.fr
 * @memberof Widgets
 * @extends {React.Component}
 * @description Widget order list content
 * @return {ReactComponent} WidgetList  
 */
class WidgetList extends Component<Props,State>  {

    render() {
        /**
         * @instance
         * @method render
         * @memberof Widgets.WidgetList
         * @return { String } JSX 
         */
        const { content, image, styles, data } = this.props;
        const img = (image !== undefined) ? image : {};
        const css = (styles !== undefined) ? styles : {};
        const image_size = ( img.size !== undefined)  ? img.size : 'tiny';
        const css_image = (css.image !== undefined)  ? css.image : {};
        const css_content = (css.content !== undefined)  ? css.content : {};
        const css_header = (css.header !== undefined)  ? css.header : {};
        const css_description = (css.description !== undefined)  ? css.description : {};

        return (
            <List className="Widget-list" divided size='huge' verticalAlign='middle'>
                {content.map((element, index) => {  
                    const header = (element.header) ? resolvePath(data, element.header) : '';
                    const description = (element.description) ? stringConcat(data, element.description) : '';
                    return (
                        <List.Item key={index}>
                            <Image avatar size={image_size} style={{...css_image}} src={images[element.icon]}/>
                            <List.Content style={{...css_content}}>
                                <List.Header style={{...css_header}} as='h3'>{header}</List.Header>
                                <List.Description style={{...css_description}}>{description}</List.Description>
                            </List.Content>
                        </List.Item>
                    )
                })}
            </List>
        );
    }
}

export { WidgetList }