// @flow
import React from 'react';
import { Auth } from 'services/authService';
import { Activate } from 'services/activateService';
import { User } from 'services/userService';
import { LocalStorage, SessionStorage } from 'services/storageService';
import jwt from 'jsonwebtoken';

const ConnectionContext = React.createContext<any>();

type Props = {
    children: any
}

type State = {
    authenticated: boolean,
    owner: boolean,
    firstConnection: boolean,
    expire: any,
    issued: any
};

/**
 * ConnectionProvider
 * @author Tomasz tpr@deltacode.fr
 * @memberof Providers
 * @extends {React.Component}
 * @description Connection provider
 */
class ConnectionProvider extends React.Component<Props,State> {

    /**
     * @constructor
     * @param { Object } props Props
     */
    constructor(props: Props) {
        super(props)
        this.state = {
            authenticated: false,
            owner: false,
            firstConnection: true,  
            expire: null,
            issued: null
        }
    }
    
    async componentDidMount(){
        /**
         * @instance
         * @async
         * @method componentDidMount
         * @memberof Providers.ConnectionProvider
         * @return { Void } 
         */
        await SessionStorage.setItem('loaded_at', Math.floor(new Date().getTime() / 1000));
        const token = await LocalStorage.getItem('token');
        if(token){
            const decoded = jwt.decode(token);
            const firstConnection = !decoded.scopes.hasOwnProperty('ccns');
            const owner = decoded.scopes.hasOwnProperty('account');
            await this.setState({ 
                authenticated: true,
                firstConnection : firstConnection,
                owner: owner,
                expire: decoded.exp,
                issued: decoded.iat
            })
        }
    } 
    
    login = async (email: string, password: string) => {
        /**
         * @instance
         * @async
         * @method login
         * @memberof Providers.ConnectionProvider
         * @param { String } email User email
         * @param { String } password User password
         * @return { Boolean } Authenticated state
         */
        try {  
            const response = await Auth.getToken(email, password);
            const payload = await response.json();
            if(response.status !== 200){
                const error = { status: response.status};
                throw error;
            }
            const decoded = jwt.decode(payload.token);
            const loaded_at =  await SessionStorage.getItem('loaded_at');
            const update_time = Math.floor(new Date().setHours(1,0,0,0) / 1000);
            if(loaded_at && Number(loaded_at) < update_time) {
                window.location.reload(true); 
                await SessionStorage.setItem('loaded_at', Math.floor(new Date().getTime() / 1000));
            }
            if(decoded.apps.includes('fasstccn')){
                await LocalStorage.setItems({
                    token: payload.token,
                    uid: decoded.uid
                });
                await this.setState({ 
                    authenticated: true,
                    owner: decoded.scopes.hasOwnProperty('account'),
                    firstConnection: !decoded.scopes.hasOwnProperty('ccns'),
                    expire: decoded.exp,
                    issued: decoded.iat
                });
                return this.state.authenticated
            } else {
                const error = { status : 403 };
                throw error;
            }
        }
        catch(error) {
            throw error;
        }
    }

    userFirstConnection = async (data: any) => {
        /**
         * @instance
         * @async
         * @method userFirstConnection
         * @memberof Providers.ConnectionProvider
         * @param { Object } data User data
         * @return { Void }
         */
        try {
            const request = await User.firstConnection(data);
            const response = await request.json()
            if(response){
                await LocalStorage.setItems({
                    token: response
                })
                await this.setState({ firstConnection : false })
            }
        }
        catch(error) {
            throw error
        }
    }

    logout = async  () => { 
        /**
         * @instance
         * @async
         * @method logout
         * @memberof Providers.ConnectionProvider
         * @return { Void }
         */
        await LocalStorage.clear();
        await this.setState({ authenticated: false })
    }

    activate = async (hash: string) => { 
        /**
         * @instance
         * @async
         * @method activate
         * @memberof Providers.ConnectionProvider
         * @param { String } hash Activate hash
         * @return { String } Account has been activated
         */
        try {
            const response = await Activate.activate(hash);
            const playload = await response.json();
            return playload
        }
        catch(error) {
            throw error
        }
    }

    expiredToken = async () => {
        /**
         * @instance
         * @async
         * @method expiredToken
         * @memberof Providers.ConnectionProvider
         * @return { Boolean } Token status
         */
        const token = await LocalStorage.getItem('token');
        if( !token || this.state.expire < Math.floor(Date.now() / 1000) ){
            return true
        }else{
            return false
        }  
    }

    render() {
        /**
         * @instance
         * @method render
         * @memberof Providers.ConnectionProvider
         * @return { String } JSX 
         */
        return (
            <ConnectionContext.Provider 
                value={{
                    ...this.state,
                    login: this.login, 
                    logout: this.logout,
                    activate: this.activate,
                    userFirstConnection: this.userFirstConnection,
                    expiredToken: this.expiredToken
                }}>
                {this.props.children}
            </ConnectionContext.Provider>
        )
    }
}

const ConnectionConsumer = ConnectionContext.Consumer

export { ConnectionProvider, ConnectionConsumer }