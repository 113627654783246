// @flow
import React from 'react';
import { Password } from 'services/passwordService';

const PasswordContext = React.createContext<any>();

type Props = {
    children:any
}

type State = {};

/**
 * PasswordProvider
 * @author Tomasz tpr@deltacode.fr
 * @memberof Providers
 * @extends {React.Component}
 * @description Password provider
 */
class PasswordProvider extends React.Component<Props,State>  {

    updatePassword = async (hash: string, password: string) => {
        /**
         * @instance
         * @async
         * @method forgetPassword
         * @memberof Providers.PasswordProvider
         * @param { String } hash User hash
         * @param { String } password User password
         * @return { String } Updated password
         */
        try {
            const response = await Password.updatePassword(hash, password);
            return response
        }
        catch(error) {
            throw error
        }
    }

    forgetPassword = async (email: string) => {
        /**
         * @instance
         * @async
         * @method forgetPassword
         * @memberof Providers.PasswordProvider
         * @param { String } email User email
         * @return { Object } Mail request
         */
        try {
            const response = await Password.forgetPassword(email);
            return response
        }
        catch(error) {
            throw error
        }
    }

    render() {
        /**
         * @instance
         * @method render
         * @memberof Providers.PasswordProvider
         * @return { String } JSX 
         */
        return (
            <PasswordContext.Provider 
                value={{
                    updatePassword: this.updatePassword,
                    forgetPassword: this.forgetPassword
                }}>
                {this.props.children}
            </PasswordContext.Provider>
        )
    }
}

const PasswordConsumer = PasswordContext.Consumer

export { PasswordProvider, PasswordConsumer }