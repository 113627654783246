// @flow
import React, { Component, Fragment } from "react";
import {
    Embed,
    Grid,
    Segment,
    Header,
    Popup,
    Button,
    Icon,
    List,
} from "semantic-ui-react";
import { Loading, Knowledge, KeyFigures } from "components";
import {
    isEmptyEveryObjects,
    numberFormat,
    getKeyFiguresYears,
    formatAddress,
} from "tools";

import "./Company.scss";

type Props = {
    consumers: any,
    location: any,
    history: any,
    match: any,
};

type State = {
    loading: boolean,
    siret: number,
    zoom: number,
    company: any,
    loadingKnowledge: boolean,
    modal: boolean,
};

const paths = {
    redirect: "/recherche/entreprises",
};

const uri = "https://maps.google.com/maps?q=";

/**
 * Company
 * @author Tomasz tpr@deltacode.fr
 * @memberof Containers
 * @extends {React.Component}
 * @description Company container
 */
class Company extends Component<Props, State> {
    /**
     * @constructor
     * @param { Object } props Props
     */
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false,
            siret: 0,
            zoom: 15,
            company: {},
            loadingKnowledge: false,
            modal: false,
        };
    }

    async componentDidMount() {
        /**
         * @instance
         * @async
         * @memberof Containers.Company
         * @method componentDidMount
         * @return { Void }
         */
        if (!this.props.location.state) {
            this.props.history.push(paths.redirect);
        } else {
            try {
                const siret = this.props.location.state.id;
                this.setState({
                    siret: siret,
                });
                let company;
                let rne = null;
                const { companies } = this.props.consumers;
                const localCompanies = await companies.getLocalCompanies();
                if (!localCompanies.length) {
                    const response = await companies.getCompany(siret);
                    company = response;
                } else {
                    const localCompanie = localCompanies.find(
                        (item) => item.siret === siret
                    );
                    if (!localCompanie) {
                        await companies.clear(["companies"]);
                        const response = await companies.getCompany(siret);
                        company = response;
                    } else {
                        company = localCompanie;
                    }
                }

                try {
                    const response = await companies.getRne(siret.slice(0, -5));
                    rne = response;
                } catch (error) {}

                this.setState({
                    loading: true,
                    company: { ...company, ...(rne && { rne: rne }) },
                });
            } catch (error) {}
        }
    }

    handleSearchKnowledge = (company: any) => async (event: any) => {
        /**
         * @instance
         * @async
         * @method handleSearchKnowledge
         * @memberof Containers.Companies
         * @description Search company data
         * @return { Void }
         */
        event.preventDefault();
        if (!company.knowledge) {
            try {
                this.setState({
                    loadingKnowledge: true,
                });
                const { companies } = this.props.consumers;
                const localCompanies = await companies.getLocalCompanies();
                const etablissement = company.uniteLegale.denomination
                    ? company.uniteLegale.denomination
                    : `${company.uniteLegale.nom} ${company.uniteLegale.prenom1}`;
                const response = await companies.getKnowledge(
                    etablissement,
                    `${company.geoAdresse} ${company.adresse.codePostal} ${company.adresse.libelleCommune}`,
                    company.siret
                );

                this.setState({
                    company: { ...company, knowledge: response },
                    loadingKnowledge: false,
                });
                await companies.setLocalCompanies(
                    companies.updateCompanies(
                        localCompanies,
                        company.siret,
                        response
                    )
                );
            } catch (error) {}
        }
    };

    render() {
        /**
         * @instance
         * @method render
         * @memberof Containers.Company
         * @return { String } JSX
         */
        const { loading, company, zoom, loadingKnowledge } = this.state;

        if (company && !loading) {
            return (
                <Grid className="Company">
                    <Grid.Row>
                        <Loading title="Chargement" />
                    </Grid.Row>
                </Grid>
            );
        } else {
            return (
                <Fragment>
                    <Grid className="Company">
                        <Grid.Row>
                            <Grid.Column>
                                <Embed
                                    active={true}
                                    url={`${uri}${formatAddress(
                                        company.adresse
                                    )}&t=&z=${zoom}&ie=UTF8&iwloc=&output=embed`}
                                ></Embed>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Grid centered>
                        <Grid.Column mobile={16} computer={10}>
                            <Segment.Group>
                                <Segment>
                                    <Header
                                        style={{ marginBottom: "0.2em" }}
                                        as="h2"
                                        textAlign="center"
                                        className="color-default company-name"
                                    >
                                        {company.uniteLegale.denomination
                                            ? company.uniteLegale.denomination
                                            : `${company.uniteLegale.nom} ${company.uniteLegale.prenom1}`}
                                    </Header>
                                    <Header
                                        style={{ margin: "0 0 1em 0" }}
                                        as="h4"
                                        textAlign="center"
                                    >
                                        {formatAddress(company.adresse)}
                                    </Header>

                                    <Popup
                                        wide="very"
                                        hoverable
                                           disabled={ !company.rne }
                                        position="top center"
                                        content={
                                            <List size="small" relaxed divided>
                                                {company &&  company.rne && 
                                                    company.rne.representants.map(
                                                        (representant) => (
                                                            <List.Item>
                                                                <List.Content>
                                                                    <List.Header>
                                                                        {" "}
                                                                        {
                                                                            representant.identite
                                                                        }
                                                                    </List.Header>{" "}
                                                                    <List.Description>
                                                                        {
                                                                            representant.qualite
                                                                        }{" "}
                                                                         ({
                                                                            representant.type
                                                                         })
                                                                    </List.Description>
                                                                </List.Content>
                                                            </List.Item>
                                                        )
                                                    )}
                                            </List>
                                        }
                                        trigger={
                                            <Button
                                         
                                            disabled={ !company.rne }
                                            basic={company.rne}
                                                style={{
                                                    position: "absolute",
                                                    top: "10px",
                                                    right: "60px",
                                                }}
                                            >
                                                Dirigeants
                                            </Button>
                                        }
                                    />

                                    {loadingKnowledge ? (
                                        <Button
                                            basic
                                            icon
                                            style={{
                                                position: "absolute",
                                                top: "10px",
                                                right: "10px",
                                            }}
                                        >
                                            <Icon
                                                color="grey"
                                                loading
                                                name="spinner"
                                            />
                                        </Button>
                                    ) : company.hasOwnProperty("knowledge") ? (
                                        <Popup
                                            hoverable
                                            position="top center"
                                            content={
                                                <Knowledge data={company} />
                                            }
                                            trigger={
                                                <Button
                                                    style={{
                                                        position: "absolute",
                                                        top: "10px",
                                                        right: "10px",
                                                    }}
                                                    icon="address card"
                                                />
                                            }
                                        />
                                    ) : (
                                        <Button
                                            basic
                                            icon
                                            style={{
                                                position: "absolute",
                                                top: "10px",
                                                right: "10px",
                                            }}
                                            active={
                                                company.knowledge ? true : false
                                            }
                                            onClick={this.handleSearchKnowledge(
                                                company
                                            )}
                                        >
                                            <Icon name="search" />
                                        </Button>
                                    )}
                                    <Grid>
                                        <Grid.Row divided>
                                            <Grid.Column
                                                mobile={16}
                                                tablet={8}
                                                computer={8}
                                            >
                                                <Header
                                                    as="h5"
                                                    textAlign="center"
                                                >
                                                    Catégorie :{" "}
                                                    <strong>
                                                        {company.uniteLegale
                                                            .natureJuridique &&
                                                            company.uniteLegale
                                                                .natureJuridique}
                                                    </strong>
                                                </Header>
                                            </Grid.Column>
                                            <Grid.Column
                                                mobile={16}
                                                tablet={8}
                                                computer={8}
                                            >
                                                <Header
                                                    as="h5"
                                                    textAlign="center"
                                                >
                                                    Activité :{" "}
                                                    <strong>
                                                        {
                                                            company
                                                                .etablissement
                                                                .classe
                                                        }{" "}
                                                        {company.etablissement
                                                            .activitePrincipaleAPE &&
                                                            `(${company.etablissement.activitePrincipaleAPE})`}
                                                    </strong>
                                                </Header>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Segment>
                                <Segment.Group horizontal>
                                    <Segment textAlign="center">
                                        Siret
                                        <br />
                                        <strong>
                                            {company.siret
                                                ? company.siret
                                                : "inconnu"}{" "}
                                        </strong>
                                    </Segment>
                                    <Segment textAlign="center">
                                        Création <br />
                                        <strong>
                                            {company.dateCreation
                                                ? company.dateCreation
                                                : "inconnue"}
                                        </strong>
                                    </Segment>
                                    <Segment textAlign="center">
                                        Effectif
                                        <br />
                                        <strong>
                                            {company.uniteLegale
                                                .trancheEffectifsLabel
                                                ? company.uniteLegale
                                                      .trancheEffectifsLabel
                                                : "inconnu"}
                                        </strong>
                                    </Segment>
                                    <Segment textAlign="center">
                                        Finances
                                        <br />
                                        <strong>
                                            Chiffre d'affaires{" "}
                                            {getKeyFiguresYears().n2} :{" "}
                                            {company.keyFigures &&
                                            company.keyFigures[
                                                `chiffre${
                                                    getKeyFiguresYears().n2
                                                }`
                                            ]
                                                ? numberFormat(
                                                      company.keyFigures[
                                                          `chiffre${
                                                              getKeyFiguresYears()
                                                                  .n2
                                                          }`
                                                      ].ca
                                                  )
                                                : "inconnu"}
                                        </strong>
                                        {company.keyFigures &&
                                        isEmptyEveryObjects(
                                            [
                                                `chiffre${
                                                    getKeyFiguresYears().n2
                                                }`,
                                                `chiffre${
                                                    getKeyFiguresYears().n3
                                                }`,
                                                `chiffre${
                                                    getKeyFiguresYears().n4
                                                }`,
                                            ],
                                            company.keyFigures
                                        ) ? (
                                            <KeyFigures company={company} />
                                        ) : null}
                                    </Segment>
                                </Segment.Group>
                            </Segment.Group>
                            <br />
                            <br />
                            <br />
                        </Grid.Column>
                    </Grid>
                </Fragment>
            );
        }
    }
}

export { Company };