
// @flow
import React, { Fragment } from 'react';
import { Route } from "react-router-dom";
import { ConnectionConsumer } from 'providers';
import {  FirstConnection, Empty } from 'containers';
import { ConsumerReducer } from 'routes';

type Props = {
    component: any,
    consumers?: any,
    authenticated: boolean,
    firstConnection: boolean,
    expiredToken: any,
    logout: any,
    location: any
}

type State = {
    expired: boolean
}

/**
 * ProtectedRoute
 * @author Tomasz tpr@deltacode.fr
 * @memberof Routes
 * @description Protected Route Component
 */
class ProtectedRoute extends React.Component<Props, State> {
    
    /**
     * @constructor
     * @param { Object } props Props
     */
    constructor(props: Props) {
        super(props)
        this.state = {
            expired: true
        }
    }

    async componentDidMount() {
        /**
         * @instance
         * @async 
         * @method componentDidMount
         * @memberof Routes.ProtectedRoute
         * @return { Void } 
         */
        const expired  = await this.props.expiredToken();
        this.setState({ expired: expired})
        if(expired){
            await this.props.logout();
        }
    }

    render() {
        /**
         * @instance
         * @method render
         * @memberof Routes.ProtectedRoute
         * @return { String } JSX 
         */
        const { 
            component:RouteComponent,
            consumers,
            authenticated, 
            firstConnection,
            expiredToken, 
            logout,
            ...rest
        }:any = this.props
        const { expired } = this.state
        const Component = expired ? Empty : RouteComponent;
        return (
            <Fragment>
                {authenticated  &&
                    <Route
                    render={props => 
                        expired ? <Route path="/" component={Component}/> : 
                        firstConnection ? ConsumerReducer(FirstConnection, [{ name: 'connection', consumer: ConnectionConsumer}], props): 
                        consumers ? ConsumerReducer(Component, consumers, props) : <Component {...props} />
                    }
                    {...rest}
                    />
                }
            </Fragment>
        )
    }
}

export { ProtectedRoute } 