// @flow
import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import { Loading } from 'components';
import { 
    Grid, 
    Header, 
    Transition, 
    Accordion, 
    Icon, 
    List, 
    Label, 
    Message,
    Segment,
    Modal,
    Button
} from 'semantic-ui-react';
import { strings, messagesFavorites } from 'constantes';
import { setMessage, capitalize } from "tools";

import './Favorites.scss'; 

type Props = {
    consumers: any
}

type State = {
    loading: boolean,
    visible: boolean,
    error: boolean,
    modalHistory: boolean,
    modalReviews: boolean,
    reviews:any,
    updates: any,
    updatesCount: number,
    favorites: any,
    message: any,
    history: any,
}

/**
 * Favorites
 * @author Tomasz tpr@deltacode.fr
 * @memberof Containers
 * @extends {React.Component}
 * @description Favorites container
 */
class Favorites extends Component<Props,State> {

    /**
     * @constructor
     * @param { Object } props Props
     */
    constructor(props: Props) {
        super(props)
        this.state = {
            error: false,
            visible: false,
            loading: false,
            modalHistory: false,
            modalReviews:false,
            reviews:[],
            updates: [],
            updatesCount: 0,
            favorites: {},
            message: {},
            history: []
        }
    }

    async componentDidMount() {
        /**
         * @instance
         * @async
         * @memberof Containers.Favorites
         * @method componentDidMount
         * @return { Void } 
        */
        try {
            await this.setState({ 
                visible: true
            });
         
            const { favorites, reviews } = this.props.consumers;
            await this.setState({ 
                favorites: await  favorites.getFavorites(),
                reviews: await reviews.getReviews(),
                updates: await reviews.getUpdates(),
                updatesCount: await reviews.getCount(),
                loading: true
            });
          
        }
        catch(error) {
            await this.setState({
                error: true,
                loading: true,
                message: setMessage(messagesFavorites, error.status)
            })
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps:any){
        /**
         * @instance
         * @async
         * @memberof Components.WithRouterHeader
         * @method componentWillReceiveProps
         * @return { Void } 
         */
        const { reviews } = this.props.consumers;
         if (reviews.updates !== nextProps.consumers.reviews.updates) {
            this.setState({
                updates: nextProps.consumers.reviews.updates,
            });
        }
        if (reviews.updatesCount !== nextProps.consumers.reviews.updatesCount) {
            this.setState({
                updatesCount: nextProps.consumers.reviews.updatesCount
            });
        }
    }

    handleReviewsModalOpen = async(event: SyntheticEvent<HTMLButtonElement>, id:number) => {
        /**
         * @instance
         * @async
         * @method handleReviewsModalOpen
         * @memberof Containers.CustomComponent
         * @description  Open confirmation modal
         * @return { Void } 
         */
   
        await this.setState({ modalReviews: true});
    }

    handleReviewsModalClose = async(event: SyntheticEvent<HTMLButtonElement>, id:number) => {
        /**
         * @instance
         * @async
         * @method handleReviewsModalClose
         * @memberof Containers.CustomComponent
         * @description  Close confirmation modal
         * @return { Void } 
         */
        const { reviews } = this.props.consumers;
        reviews.confirmUpdates();
        await this.setState({ modalReviews: false });
    }

    handleHistoryModalOpen = async(event: SyntheticEvent<HTMLButtonElement>, id:string) => {
        /**
         * @instance
         * @async
         * @method handleModalOpen
         * @memberof Containers.CustomComponent
         * @description Open confirmation modal
         * @return { Void } 
         */
        const temp:any = this.state.reviews.find(item => item.ref === id)
        event.stopPropagation();
        await this.setState({ modalHistory: true, history: temp });
    }

    handleHistoryModalClose = async () => {
        /**
         * @instance
         * @async
         * @method handleModalClose
         * @memberof Containers.CustomComponent
         * @description Close confirmation modal
         * @return { Void } 
         */

        await this.setState({ modalHistory: false });
    }

    hasReviews = (id:string) => {
        /**
         * @instance
         * @method hasReviews
         * @memberof Containers.Favorites
         * @param { Array } reviews List of reviews
         * @return { Boolean } Own reviews
         */
        return this.state.reviews.find(review => review.ref === id);
    }
   
    panels = (data:any) => {    
        
       
        /**
         * @instance
         * @method panels
         * @memberof Containers.Favorites
         * @param { Array } data List of panels
         * @return { Array } Panels
         */
        const panels = [];
        Object.keys(data).sort().forEach((panel,i) => {  
            let title = 
            <Grid stackable>
                <Grid.Column width={8}>
                    <Header as='h2' content={
                        <Fragment>
                            {strings.favorites[panel]}
                            <Label circular color='grey'>{data[panel].length}</Label>
                        </Fragment> 
                    }/>
                   </Grid.Column>
                   <Grid.Column textAlign='right' width={7}>
                    {panel === 'ccn' && (
                        <Fragment>
                        {this.state.updatesCount ?  (
                        <Button  onClick={this.handleReviewsModalOpen} basic size='tiny'>
                            Afficher les mises à jour 
                            <Label horizontal size='tiny'>
                                {this.state.updatesCount} 
                            </Label>
                        </Button>  
                        ):null}
                        </Fragment>
                    )}
                    </Grid.Column>
                      <Grid.Column width={1}>
                    <Icon circular name='plus' className='toogle'/>
                </Grid.Column>
            </Grid> 
                
            let items = []
            data[panel].map((item,i) => {
                item.scope = panel;
                return items.push( {
                    key: i, 
                    content: 
                    <Grid stackable>
                        <Grid.Column width={12}>
                            <Link to={{ 
                                    pathname: `/${panel.toLowerCase()}/${item.id}`, 
                                    state: item
                                }}>{panel === 'ccn' ? (
                                    <Header 
                                        as='h3' 
                                        className="color-default" 
                                        content={`IDCC ${item.idcc} - ${capitalize(item.description)}`} 
                                        subheader={`${capitalize(item.title)}`}
                                    />
                                ):(
                                    <Header 
                                        as='h3'
                                        className="color-default"
                                        content={item.title}
                                        subheader={`SIRET ${item.id} - ${item.description}`}
                                    />
                                )}
                            </Link>
                            </Grid.Column >
                                    { this.hasReviews(item.id) ? (
                            <Grid.Column textAlign='right' width={4}>
                                    <Button basic size='mini' onClick={(event)=>this.handleHistoryModalOpen(event, item.id)}>Historique</Button>
                            </Grid.Column> ):null}
                        </Grid>
                    })
                });

            return panels.push({
                key: i,
                title:{ content:title}, 
                content: {content: <List animated selection divided items={items} />}
            });
        });
        return panels;
    }   

    render() {
        /**
         * @instance
         * @method render
         * @memberof Containers.Favorites
         * @return { String } JSX 
         */
        const { loading, error, visible, favorites, message, modalHistory, modalReviews, history, updates } = this.state
        const panels = Object.keys(favorites).length > 0 ? this.panels(favorites.metaValue) : this.state.favorites; 
        return (
            <Fragment>
                <Modal className='result' open={modalReviews} centered={false}>
                    <Modal.Header>Les notifications</Modal.Header>
                    <Modal.Content scrolling>
                    <Modal.Description>
                        {modalReviews &&
                            <List divided verticalAlign='middle' className="reviews">
                            {updates.map((items,index) => (
                                <List.Item key={index}>
                                        <List.Header>CCN {items.reviews[0].idcc}</List.Header> 
                                        <List.Content >
                                            {items.reviews.map((item, index)=>
                                                (
                                                <List.List className='sublist' key={index}>
                                                    <List.Item> Notification {new Date(item.created * 1000).toLocaleDateString("FR-fr")} 
                                                    <List.Description>{item.content}</List.Description>
                                                        </List.Item>
                                                </List.List>
                                                )
                                            )}    
                                        </List.Content>
                                </List.Item> )
                            )}
                            </List>
                        }
                    </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button className="button btn default" size='small' onClick={this.handleReviewsModalClose}>Fermer </Button>
                    </Modal.Actions>
                </Modal>
                <Modal className='result' open={modalHistory} centered={false}>
                    <Modal.Header>Historique</Modal.Header>
                    <Modal.Content scrolling>
                    <Modal.Description>
                        {modalHistory &&
                        <List divided verticalAlign='middle' className="reviews">
                            {history.reviews.map((item,index) => (
                                <List.Item key={index}>
                                    <List.Content >
                                    <List.Header>Notification {new Date(item.created * 1000).toLocaleDateString("FR-fr")}</List.Header>
                                    <List.Description>  {item.content}</List.Description>
                                    </List.Content>
                                </List.Item>)
                            )}
                        </List>
                        }
                    </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button className="button btn default" size='small' onClick={this.handleHistoryModalClose}>Fermer </Button>
                    </Modal.Actions>
                </Modal>
                <Grid className="Favorites page bg-gradient" >
                    <Grid.Row >
                        <Grid.Column>
                            <Transition visible={visible} animation='fade up' duration={500}>
                                <Header as='h2' textAlign='center' className='lead'>
                                    Vos CCN <br/> & Entreprises favorites
                                </Header>
                            </Transition>
                            {loading ? (
                                <Fragment>
                                    {error ? (  
                                    <Segment>
                                        <Message color={message.color} content={message.text}/>
                                    </Segment>
                                    ):(
                                    <Accordion fluid panels={panels}></Accordion>
                                    )}
                                </Fragment>
                            ) : (
                                <Segment padded>
                                    <Loading title='Chargement' className='loader'/>
                                </Segment>
                            )}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Fragment>
        )
    }
}

export { Favorites }