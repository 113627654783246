// @flow
import { checkStatus, checkError } from 'tools';

/**
 * Activate service
 * @author Tomasz tpr@deltacode.fr
 * @memberof Services
 * @description Activate service
 */
class Activate {

    static entrypoints:any = {
        activate : 'activate'
    }
    
    static url: any = process.env.REACT_APP_API_URL;

    static activate = async (hash:string) => {
        /**
         * Activate user
         *
         * @async
         * @method activate
         * @memberof Services.Activate
         * @param { String } hash Activate hash
         * @return { String } Activate message
        */
        try {
            const response = await fetch(`${this.url}/${this.entrypoints.activate}/${hash}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                mode: 'cors',
            });
            return checkStatus(response);
        } catch(error) {
            throw checkError(error);
        }
    }
}
export  { Activate };