// @flow
import React, { Component } from "react";
import Bot from 'react-simple-chatbot';
import { Grid, Header, Transition, Button, Container } from 'semantic-ui-react';
import { CustomComponent }  from './CustomComponent.js';
import { logoChatBot, userChatBot } from "constantes";
import { isEmptyObject  } from "tools";

import './ChatBot.scss';

type Props = {
    consumers: any,
    history: any
}

type State = {
    loading: boolean,
    visible: boolean,
    steps: Array<any>
}

/**
 * ChatBot Container
 * @author Tomasz tpr@deltacode.fr
 * @memberof Containers
 * @extends {React.Component}
 * @description ChatBot
 * @return {ReactComponent} ChatBot
 */
class ChatBot extends Component<Props,State> {

    /**
     * @constructor
     * @param { Object } props Props
    */
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            visible: false,
            steps: []
        }
    }

    async componentDidMount(){
        /**
         * @instance
         * @async
         * @memberof Containers.ChatBot
         * @method componentDidMount
         * @return { Void } 
        */
        const { consumers } = this.props
        const cache = await consumers.chatbot.getCache();
      
        let  steps = [
            {
                id: '1',
                message: 'Bonjour je suis là pour vous aider à trouver les CCN qui vous intéressent le plus !',
                trigger: 'search',
            },
            {
                id: '3',
                component: <CustomComponent consumer={consumers.chatbot}/>,
                waitAction: true,
                trigger: '3',
            },
            {
                id: 'search',
                user: true,
                trigger: '3',
            },
            {
                id: '4',
                message: 'Oups, on efface tout et on recommence',
                trigger: 'search',
            },
        ]

        if(!isEmptyObject(cache)){
            steps.shift()
        }

        await this.setState({  
            loading :true,
            visible: true,
            steps: steps
        });
    }

    resetBot = async ()=> {
        /**
         * @instance
         * @method resetBot
         * @memberof Containers.ChatBot
         * @return { Void } 
         */

        const { consumers } = this.props
        const type = 'text'
        const  message = {
            text: 'bonjour'}
        
        await consumers.chatbot.messaging(type, message);
      
        const cache = await consumers.chatbot.clearCache();
        if( cache !== undefined && !isEmptyObject(cache)){ 
         await consumers.chatbot.clearCache();
        }
        this.props.history.go('/chatbot');
    }

    render() {
        /**
         * @instance
         * @method render
         * @memberof Containers.ChatBot
         * @return { String } JSX 
        */
        const { loading, visible, steps } = this.state
        
        return(
            <Grid className="ChatBot page bg-gradient">
                <Grid.Row>
                    {loading && 
                        <Grid.Column>
                            <Transition visible={visible} animation='fade up' duration={500}>
                                <Header as='h2' textAlign='center' className='lead'>
                                    Trouver toutes les informations <br/>dont vous pouvez avoir besoin
                                </Header>
                            </Transition>
                            <Bot
                                hideHeader={true}
                                placeholder="Ecrivez votre message... "
                                width="100%"
                                customDelay="0"
                                botAvatar={logoChatBot}
                                userAvatar={userChatBot}
                                recognitionLang="fr"
                                bubbleStyle={{
                                background: "#ff595e !important"
                                }}             
                                style={{
                                    "headerFontSize": "15px",
                                    "background": "white",
                                    "fontFamily": "Lato,Helvetica Neue,Arial,Helvetica,sans-serif",
                                }}
                                steps={steps}
                            />
                             <Container className='reset' textAlign='center'>
                                <Button basic icon='redo' onClick={this.resetBot} content='Recommencer' size='small' />
                             </Container>
                        </Grid.Column>
                    }
                </Grid.Row>      
            </Grid>
        )
    }
}

export { ChatBot }