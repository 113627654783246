// @flow
import React, { Component, Fragment } from "react";
import { Wrapper, Loading } from "components";
import {
  Grid,
  Transition,
  Header,
  Modal,
  FormGroup,
  Button,
  Message,
  Input as SemanticInput,
  Icon,
  Table,
  Pagination,
} from "semantic-ui-react";
import { Form, Input } from "semantic-ui-react-form-validator";
import { messagesForm, messageUsers, messagesUserDelete, messagesUserAdd } from "constantes";
import { getPaginationArray, isEmptyObject, setMessage } from "tools";

import "./Users.scss";

type Props ={
    consumers: any
}

type State = {
    visible: boolean,
    modal: boolean,
    loading: boolean,
    awaiting: boolean,
    deleting: boolean,
    error: boolean,
    userSearch: string,
    userEmail: string,
    userDeleted: any,
    users: Array<any>,
    usersFiltered:  Array<any>,
    usersDisplayed:  Array<any>,
    activePage: number,
    message: any
};


/**
 * Users
 * @author Hugo hle@deltacode.fr
 * @memberof Containers
 * @extends {React.Component}
 * @description Users container
 */
class Users extends Component<Props,State> {

    /**
     * @constructor
     * @param { Object } props Props
    */
    constructor(props: Props) {
        super(props)
        this.state = {
            modal: false,
            loading: false,
            visible: false,
            awaiting: false,
            deleting: false,
            error: false,
            userSearch: "",
            userEmail: "",
            userDeleted: {},
            users: [],
            usersFiltered: [],
            usersDisplayed: [],
            activePage: 1,
            message: {},
        }
    }

    async componentDidMount() {
        /**
         * @instance
         * @async
         * @memberof Containers.Users
         * @method componentDidMount
         * @return { Void } 
        */
        await this.setState({ visible: true });
        try{
            const { user } = this.props.consumers;
            const response = await user.getUsers();
            
            await this.setState({ 
                loading: true,
                users: response, 
                usersFiltered: response,
                usersDisplayed: getPaginationArray(response, this.state.activePage)
            });
        } catch(error) {
            this.setState({ 
                error: true,
                message: setMessage(messageUsers, error.status)
            });
        }
    }

    handlePageChange = async (event: SyntheticEvent<HTMLButtonElement>, data: any)  => {
        /**
         * @instance
         * @async
         * @method handlePageChange
         * @memberof Containers.Users
         * @description Change page
         * @params { Object } event Event
         * @params { Number } data Number of page
         * @return { Void } 
         */
        this.setState({
            usersDisplayed: getPaginationArray(this.state.usersFiltered, data.activePage),
            activePage: data.activePage
        });
    };

    handleInputChange = async (event:SyntheticInputEvent<>, { name, value }:any) => {
        /**
         * @instance
         * @memberof Containers.Users
         * @method handleInputChange
         * @params { Object } event Event
         * @params { String } name Form name
         * @params { String } name Form value
         * @return { Void } 
        */
        await this.setState({ [name]: value, message: {} }, () => {
        if (name === "userSearch") {
            if (this.state.userSearch === "") {
                this.setState({ usersFiltered: this.state.users });
            } else {
            this.setState({
                usersFiltered: this.state.users.filter(user => {
                const regex = new RegExp(
                    this.state.userSearch.toLowerCase(),
                    "g"
                );
                let str = "";
                if (user.activate && user.firstName && user.lastName) {
                    str = `${user.email.toLowerCase()} ${user.firstName.toLowerCase()} ${user.lastName.toLowerCase()}`
                } else {
                    str = user.email.toLowerCase();
                }
                const matches = str.match(regex);
                return (matches) ? true : false
                })
            });
            }
        }
        });
        this.setState({
            usersDisplayed: getPaginationArray(this.state.usersFiltered, this.state.activePage)
        });
    };

    handleDelete = async (uid: string) => {
        /**
         * @instance
         * @async
         * @memberof Containers.Users
         * @method handleDelete
         * @params { String } uid User uid
         * @return { Void } 
        */
        await this.setState({ deleting: true });
        try{
            const { user } = this.props.consumers;
            await user.deleteUser(uid);
            const users  = this.state.users.filter(( obj ) => {
                return obj.uid !== uid;
            });
           await this.setState({ 
                users: users,
                usersFiltered: users,
                usersDisplayed: getPaginationArray(users, this.state.activePage),
                deleting: false,
                modal: false,
                message: setMessage(messagesUserDelete, 200)
            });
        } catch(error) {
            this.setState({ 
                message: setMessage(messagesUserDelete, error.status)
            });
        }
    }

    handleSubmit = async (event: SyntheticEvent<HTMLButtonElement>) => {
        /**
         * @instance
         * @async
         * @memberof Containers.Users
         * @method handleSubmit
         * @return { Void } 
        */
        await this.setState({ awaiting: true, message: {}});
        try {
            const { user } = this.props.consumers;
            await user.addUser({email: this.state.userEmail});
            try{
                const users = await user.getUsers();
                await this.setState({ 
                    awaiting: false,
                    message: setMessage(messagesUserAdd, 200),
                    userSearch: "",
                    users: users, 
                    usersFiltered: users,
                    usersDisplayed: getPaginationArray(users, this.state.activePage)
    
                });
            } catch(error) {
                this.setState({ 
                    awaiting: false,
                    message: setMessage(messageUsers, error.status)
                });
            }
        } catch(error)  {   
            this.setState({ 
                awaiting: false, 
                message: setMessage(messagesUserAdd, error.status)
            });
        }
    };

    openModal = (user: any) => {
        /**
         * @instance
         * @memberof Containers.Users
         * @method openModal
         * @return { Void } 
        */
        this.setState({
            message:{},
            userDeleted: user,
            modal: true 
        });
    }


    closeModal = () => {
        /**
         * @instance
         * @memberof Containers.Users
         * @method closeModal
         * @return { Void } 
        */
        this.setState({ modal: false, userDeleted: {} });
    }

    render() {
        /**
         * @instance
         * @method render
         * @memberof Containers.Users
         * @return { String } JSX 
         */
        const {
            modal = false,
            loading,
            visible,
            awaiting,
            deleting,
            error,
            usersFiltered,
            usersDisplayed,
            userSearch,
            userEmail,
            userDeleted,
            message
        } = this.state;

    return (
        <Fragment>
            <Grid className="Users page bg-gradient">
                <Grid.Row>
                    <Grid.Column>
                        <Transition visible={visible} animation="fade up" duration={500}>
                            <Header as="h2" textAlign="center" className="lead">
                            Utilisateurs
                            </Header>
                        </Transition>
                        {loading ? (
                            <Fragment>
                                <Wrapper name="block">
                                    { !isEmptyObject(message) && <Message color={message.color} content={message.text}/> }
                                    <Grid>
                                    {error ? (<Message color={message.color} content={message.text}/>) :(
                                        <Grid.Row>
                                            <Grid.Column
                                                mobile={16}
                                                tablet={8}
                                                computer={8}
                                                largeScreen={8}
                                                textAlign="center"
                                            >
                                                <Header
                                                    className="color-gray"
                                                    as="h3"
                                                    content="Rechercher"
                                                />
                                                <SemanticInput
                                                    size="mini"
                                                    name="userSearch"
                                                    value={userSearch}
                                                    onChange={this.handleInputChange}
                                                    className="searchBar"
                                                    icon="search"
                                                    placeholder="Rechercher..."
                                                />
                                            </Grid.Column>
                                            <Grid.Column
                                                mobile={16}
                                                tablet={8}
                                                computer={8}
                                                largeScreen={8}
                                                textAlign="center"
                                            >
                                                <Header
                                                    className="color-gray user-add"
                                                    as="h3"
                                                    content="Ajouter un utilisateur"
                                                />
                                                <Form onSubmit={this.handleSubmit}>
                                                    <FormGroup unstackable inline>
                                                        <Input
                                                            width={14}
                                                            name="userEmail"
                                                            value={userEmail}
                                                            onChange={this.handleInputChange}
                                                            validators={["required", "isEmail"]}
                                                            errorMessages={[ messagesForm.required, messagesForm.isEmail]}
                                                            type="email"
                                                            placeholder="E-mail"
                                                            className="color-gray"
                                                        />
                                                        <Button
                                                            loading={awaiting}
                                                            disabled={awaiting}
                                                            width={2}
                                                            type="submit"
                                                            icon="add"
                                                            size="large"
                                                            color="green"
                                                        />
                                                    </FormGroup>
                                                </Form>
                                            </Grid.Column>
                                        </Grid.Row>
                                        ) }
                                    </Grid>
                                </Wrapper>
                                <Wrapper name="block">
                                    <Table basic="very" unstackable>
                                        <Table.Body>
                                            {usersDisplayed.length === 0 ? (
                                            <Table.Row>
                                                <Table.Cell textAlign="center" width="16">
                                                Aucun utilisateur trouvé...
                                                </Table.Cell>
                                            </Table.Row>
                                            ) : (
                                            <Fragment>
                                                {usersDisplayed.map((user, index) => {
                                                return (
                                                    <Table.Row key={index} verticalAlign="middle">
                                                    <Table.Cell width="1">
                                                        {user.activate ? (
                                                        <Icon name="check circle" color="green" />
                                                        ) : (
                                                        <Icon name="hourglass" color="grey" />
                                                        )}
                                                    </Table.Cell >
                                                    <Table.Cell width="6">
                                                        {user.email}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {user.activate && user.firstName && user.lastName ? (
                                                        <span>
                                                            {user.firstName} {user.lastName}
                                                    
                                                        </span>
                                                        ) : (
                                                        <Fragment />
                                                        )}
                                                        </Table.Cell>
                                                    
                                                    <Table.Cell width="2" textAlign="right">
                                                        <Button onClick={() => this.openModal(user)} basic icon="trash alternate" />
                                                    </Table.Cell>
                                                    </Table.Row>
                                                );
                                                })}
                                            </Fragment>
                                            )}
                                        </Table.Body>
                                    </Table>
                                    <Modal open={modal} onClose={this.closeModal} size="tiny" >
                                        <Modal.Header content="Supprimer l'utilisateur" />
                                        <Modal.Content>
                                            {`Êtes-vous sûr de vouloir supprimer définitivement cet utilisateur ${userDeleted.email} ?`}
                                        </Modal.Content>
                                        <Modal.Actions>
                                            <Button onClick={this.closeModal} basic content="Annuler" />
                                            <Button 
                                                disabled={deleting} 
                                                loading={deleting} 
                                                onClick={() => this.handleDelete(userDeleted.uid)} 
                                                color="red" 
                                                content="Supprimer" 
                                                icon="trash alternate" 
                                            />
                                        </Modal.Actions>
                                    </Modal>
                                </Wrapper>
                                <Wrapper name="block">
                                    <Pagination
                                    onPageChange={this.handlePageChange}
                                    boundaryRange={0}
                                    defaultActivePage={1}
                                    ellipsisItem={null}
                                    firstItem={null}
                                    lastItem={null}
                                    siblingRange={1}
                                    totalPages={Math.ceil(usersFiltered.length / 20)}
                                    />
                                </Wrapper>
                            </Fragment>
                        ) : (
                        <Wrapper name="block">
                            <Loading title="Chargement" />
                        </Wrapper>
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
      </Fragment>
    );
  }
}

export { Users };