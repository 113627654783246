// @flow
import React, { Component } from 'react';
import { History } from 'components'
import { Grid, Transition, Header, Segment, Search, Message } from 'semantic-ui-react';
import { isEmptyObject, capitalize } from 'tools';
import { strings, messagesFinder } from 'constantes';
import { setMessage, removeAccents } from "tools";

import './Finder.scss'; 

type Props = {
    consumers: any,
    match: any,
    history: any
}

type State = {
    visible: boolean,
    searchLoading: boolean,
    results: any,
    data: any,
    panels: Array<any>,
    value: string,
    placeholder: string,
    message: any
}

/**
 * Finder
 * @author Tomasz tpr@deltacode.fr
 * @memberof Containers
 * @extends {React.Component}
 * @description Finder container
 */
class Finder extends Component<Props, State> {

    _isMounted = false;  
    
    /**
     * @constructor
     * @param { Object } props Props
     */
    constructor(props: Props) {
        super(props)
        this.state = {
            visible: true,
            searchLoading: false,
            results: [],
            data: [],
            value: '',
            panels: [],
            placeholder: 'Code APE, IDCC, Code Brochure, Intitulé',
            message: {},
        }
    }

    async componentDidMount() {
        /**
         * @instance
         * @async
         * @memberof Containers.Finder
         * @method componentDidMount
         * @return { Void } 
        */
        this._isMounted = true; 
        try { 
            const { ccns } = this.props.consumers;
            const response = await ccns.search();
             if (this._isMounted) {
                    await this.setState({
                        data: response
                    })
            };
        } catch(error) {
             if (this._isMounted) {
            this.setState({ 
                message: setMessage(messagesFinder, error.status)
            });
            }
        }
    }

    async componentWillUnmount(){
        /**
         * @instance
         * @async
         * @memberof Containers.Finder
         * @method componentWillUnmount
         * @return { Void } 
        */
        this._isMounted = false;
    }

    resetComponent = () => {
        /**
         * @instance
         * @async
         * @memberof Containers.Finder
         * @method resetComponent
         * @return { Void } 
        */
        this.setState({ searchLoading: false, results: [], value: '' })
    }

    handleResultSelect = async (event:SyntheticInputEvent<>, {result}:any) => {
        /**
         * @instance
         * @async
         * @memberof Containers.Finder
         * @method handleResultSelect
         * @params { Object } event Event
         * @params { Number } result Ccn selected event
         * @return { Void } 
        */
        this.resetComponent();
        const { scope } = this.props.match.params
        const url = (scope === 'ccn') ? 'ccn' : 'entreprises';
        this.props.history.push({pathname:`/${url}/${result.ref}`, state: {
            id: result.ref, 
            idcc: result.idcc, 
            title: result.title, 
            description: result.description, 
            scope: url
        }});
    }

    handleSearchChange = async (event:SyntheticInputEvent<>, { value }:any) => {
        /**
         * @instance
         * @async
         * @memberof Containers.Finder
         * @method handleSearchChange
         * @params { Object } event Event
         * @params { Number } Update input form event
         * @return { Void } 
        */
        await this.setState({ searchLoading: true,  value});
        if ( value.length < 1) return this.resetComponent()
        const results = this.state.data.filter((item, index) => {
            if( 
                item.idcc === Number(value) || 
                item.brochure === value || 
                removeAccents(item.title.toLowerCase()).includes(removeAccents(value.toLowerCase())) || 
                item.ape.includes(value.toUpperCase())){
                item['title'] = capitalize(item.title)
                return item
            }
            return false
        });
        await this.setState({
            results: results,
            searchLoading: false
        })
    }

    render() {
        /**
         * @instance
         * @method render
         * @memberof Containers.Finder
         * @return { String } JSX 
         */
        const { visible, placeholder, searchLoading, value, results, message } = this.state
        const { clearHistory, getHistory } = this.props.consumers.history
        const { scope } = this.props.match.params
        return (
            <Grid columns={1} className="Finder page bg-gradient">
                <Grid.Row>
                    <Grid.Column className="search-container">
                        <Transition visible={visible} animation='fade up' duration={500}>
                            <Header as='h2' textAlign='center' className='lead'>
                                {strings.history[scope].title}
                            </Header>
                        </Transition> 
                        <Segment textAlign='center' className="color-default">
                            <Search
                                loading={searchLoading}
                                onResultSelect={(event, { result }) => this.handleResultSelect(event, { result })}
                                onSearchChange={this.handleSearchChange}
                                noResultsMessage="Aucun résultat trouvé"
                                results={results}
                                value={value}
                                minCharacters={1}
                                size="large"
                                input={{ icon: 'search', iconPosition: 'left', placeholder: placeholder }}
                            />
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                    { !isEmptyObject(message) && <Message color={message.color} content={message.text}/> }
                        <History scope={scope} getHistory={getHistory} clearHistory={clearHistory} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export { Finder }