// @flow
import React from 'react';
import jwt from 'jsonwebtoken';
import { User } from 'services/userService';
import { Mail } from 'services/mailService';
import { LocalStorage } from 'services/storageService'; 

const MailContext = React.createContext<any>();

type Props = {
    children:any
}

type State = {};

/**
 * MailProvider
 * @author Tomasz tpr@deltacode.fr
 * @memberof Providers
 * @extends {React.Component}
 * @description Mail provider
 */
class MailProvider extends React.Component<Props,State> {

    sendMail = async (subject: string, content: string) => {
        /**
         * @instance
         * @async
         * @method sendMail
         * @memberof Providers.MailProvider
         * @param { String } subject Mail subject
         * @param { String } content Mail content
         * @return { String } Server message
         */
        const token = await LocalStorage.getItem('token');
        const decoded = jwt.decode(String(token));
        const response = await User.getUser(decoded.uid);
        const user = await response.json();
        const message  = `${content} ${user.email}`
        try {
            const response = await Mail.send(subject, message);
            return response
        }
        catch(error) {
            throw error
        }
    }

    render() {
        /**
         * @instance
         * @method render
         * @memberof Providers.MailProvider
         * @return { String } JSX 
         */
        return (
            <MailContext.Provider 
                value={{
                    sendMail: this.sendMail
                }}>
                {this.props.children}
            </MailContext.Provider>
        )
    }
}

const MailConsumer = MailContext.Consumer

export { MailProvider, MailConsumer }