// @flow
import React, { Component } from "react";
import { Transition, Header, Grid, Message } from "semantic-ui-react";
import { Wrapper,  Loading } from "components";
import { AccountForm } from "forms";
import { 
    messagesAccountGet
    } from "constantes";
import { isEmptyObject, setMessage } from "tools";

type Props = {
    consumers: any
};

type State = {
    loading: boolean,
    visible: boolean,
    errorAccount: boolean,
    account: any,
    messageAccount: any,
};

/**
 * Account
 * @author Hugo hle@deltacode.fr
 * @memberof Containers
 * @extends {React.Component}
 * @description Account container
 */
class Account extends Component<Props, State> {
    
    constructor(props: Props) {
        /**
         * @constructor
         */
        super(props)
        this.state = {
            loading: false,
            visible: false,
            errorAccount: false,
            account: {},
            messageAccount: {}
        };
    }

    async componentDidMount() {
        /**
         * @instance
         * @async
         * @memberof Containers.Account
         * @method componentDidMount
         * @return { Void } 
         */
        await this.setState({ visible: true });
        const { account } = this.props.consumers;
        try{
            const response = await account.getAccount();
            await this.setState({
                account : response
            });
    
        } catch(error) {
            await this.setState({ 
                errorAccount: true,
                messageAccount: setMessage(messagesAccountGet, error.status)    
            });
        } 
        
        await this.setState({ loading: true });
    }

    render() {
        /**
         * @instance
         * @method render
         * @memberof Containers.Account
         * @return { String } JSX 
         */
        const { 
            loading, 
            visible,
            errorAccount,
            account, 
            messageAccount
        } = this.state;
        const { consumers } = this.props;
        return (
            <Grid className="Account page bg-gradient">
                <Grid.Row>
                    <Grid.Column>
                        <Transition visible={visible} animation="fade up" duration={500}>
                            <Header as="h2" textAlign="center" className="lead">
                                Mon compte
                            </Header>
                        </Transition>
                        {loading ? (
                            <Wrapper name="block">
                                { !isEmptyObject(messageAccount) && <Message color={messageAccount.color} content={messageAccount.text}/> }
                                <Grid> {!errorAccount &&
                                    <Grid.Row columns={2}>                              
                                        <Grid.Column computer={6} tablet={6} mobile={16} verticalAlign="middle">
                                            <Header className="color-gray" as="h3" content="Informations utilisateur"/>
                                        </Grid.Column>
                                      
                                            <Grid.Column computer={10} tablet={10} mobile={16} textAlign="right" >
                                        
                                            </Grid.Column>
                                     
                                    </Grid.Row>}
                                </Grid>
                                {!errorAccount &&
                                    <AccountForm consumers={consumers} account={account}/>
                                }
                            </Wrapper> 
                        ) : (
                            <Wrapper name="block">
                                <Loading title="Chargement" />
                            </Wrapper>
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }
}

export { Account };