// @flow
import React, { Component } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { 
    AccountProvider,
    ConnectionProvider,
    FavoritesProvider,
    UserProvider,
    CcnProvider, 
    CompaniesProvider,
    ChatBotProvider,
    HistoryProvider,
    MailProvider,
    ReviewsProvider,
    PasswordProvider
    } from 'providers';
import { 
    ConnectionConsumer, 
    FavoritesConsumer,
  
} from 'providers';
import { PublicRoutes, PrivateRoutes } from 'routes';
import { Header, Footer} from 'components';
import Provider from 'Provider';
import './App.scss'; 

/**
 * App 
 * @author Tomasz tpr@deltacode.fr
 * @memberof App
 * @extends {React.Component}
 * @description App Component 
 */
class App extends Component<{}> {

    render() {
         /**
         * @instance
         * @method render
         * @memberof App.App
         * @return { String } JSX 
         */
        return (
            <ConnectionProvider>
                <ConnectionConsumer>
                    {props => (
                        <Router>
                            <AccountProvider> 
                            {props.authenticated
                                ?   <Provider providers={[
                                        ReviewsProvider,
                                        FavoritesProvider,
                                        UserProvider,
                                        CcnProvider,
                                        CompaniesProvider,
                                        ChatBotProvider,
                                        HistoryProvider,
                                        MailProvider,
                                       
                                    ]}>
                                        <FavoritesConsumer>
                                            {favorites => (
                                                <Header
                                                    favorites={favorites}
                                                    firstConnection={props.firstConnection}
                                                    expiredToken={props.expiredToken}
                                                />
                                            )}
                                        </FavoritesConsumer>
                                        <PrivateRoutes {...props}/>
                                            {!props.firstConnection  ? <Footer/>: null}
                                    </Provider> 
                                :  <PasswordProvider><PublicRoutes /></PasswordProvider>
                            }    
                            </AccountProvider>  
                        </Router> 
                    )}
                </ConnectionConsumer>            
            </ConnectionProvider>
        );
    }
}

export default App;
