// @flow
import { LocalStorage } from './storageService';
import { checkStatus, checkError } from 'tools';

/**
 * Mail service
 * @author Tomasz tpr@deltacode.fr
 * @memberof Services
 * @description Mail service
 */

 class Mail {
     
    static url:any = process.env.REACT_APP_API_URL;
    static recipient:any = process.env.REACT_APP_RECIPIENT;
    static entrypoints:any = {
        send : 'send'
    }

    static send = async (subject:string, content:string) => {    
        /**
         * Mail send
         *
         * @async
         * @method send
         * @memberof Services.Mail
         * @param { String } subject Message subject
         * @param { String } content Message content
         * @return { Object } ResponseMetadata
        */
        try {
            const token = await LocalStorage.getItem('token') || '{}';
            const response = await fetch(`${this.url}/mail/${this.entrypoints.send}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                mode: 'cors',
                body: JSON.stringify({ 
                    sender: 'fasst',
                    recipient: this.recipient,
                    content: content,
                    subject: subject
                })
            });
            return checkStatus(response);
        } catch(error) {
            throw checkError(error);
        }
    }
 }
 export { Mail };