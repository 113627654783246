// @flow
import { LocalStorage } from './storageService';
import { checkStatus, checkError } from 'tools';

/**
 * Account service
 * @author Tomasz tpr@deltacode.fr
 * @memberof Services
 * @description Account service
 */
class Account {

    static url:any = process.env.REACT_APP_API_URL;
    
    static entrypoints: any = {
        getAccount: 'account',
        updateAccount: 'account',
        firstConnection: 'account/firstConnection'
    }

    static getAccount = async () => {
        /**
         * Get account
         *
         * @async
         * @memberof Services.Account
         * @method getAccount
         * @return { Object } Account data
        */
        try {
            const token = await LocalStorage.getItem('token') || '{}';
            const response = await fetch(`${this.url}/${this.entrypoints.getAccount}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                mode: 'cors'
            });
            return checkStatus(response);
        } catch(error) {
            throw checkError(error);
        }
    }

    static updateAccount = async (data:any) => {
        /**
         * Update account
         *
         * @async
         * @method updateAccount
         * @memberof Services.Account
         * @param { Object } data Account data
         * @return { String } Update account massage
        */
        try {
            const token = await LocalStorage.getItem('token') || '{}';
            const response = await fetch(`${this.url}/${this.entrypoints.updateAccount}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                mode: 'cors',
                body: JSON.stringify(data)
            });
            return checkStatus(response);
        } catch(error) {
            throw checkError(error);
        }
    }

    static firstConnection = async (data:any) => {
        /**
         * First Connection
         *
         * @async
         * @method firstConnection
         * @memberof Services.Account
         * @param { Object } data Account data
         * @return { String } Token
        */
        try {
            const token = await LocalStorage.getItem('token') || '{}';
            const response = await fetch(`${this.url}/${this.entrypoints.firstConnection}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                mode: 'cors',
                body: JSON.stringify(data)
            });
            return checkStatus(response);
        } catch(error) {            
            throw checkError(error);
      }
    }
}

export  { Account };