// @flow
import React from 'react';
import { Metadata } from 'services/metadataService';
import { arrayFirst } from 'tools';

const FavoritesContext = React.createContext<any>();

const metaName= 'ernest-favorites';

type Props = {
    children: any
}

type State = {};

/**
 * FavoritesProvider
 * @author Tomasz tpr@deltacode.fr
 * @memberof Providers
 * @extends {React.Component}
 * @description Favorites provider
 */
class FavoritesProvider extends React.Component<Props,State> {

    getFavorites = async () => {
        /**
         * @instance
         * @async
         * @method getFavorites
         * @memberof Providers.FavoritesProvider
         * @return { Array } User favorits
         */
        try {
            const response =  await Metadata.get(metaName);
            const favorites = await response.json();
            if(!favorites.length){
                const data = { ccn: [], entreprise: []}
                await Metadata.post(data, metaName);
                const response =  await Metadata.get(metaName);
                const favorites = await response.json();
                return arrayFirst(favorites)
            }
            return arrayFirst(favorites)
        }catch(error){
            throw error
        }
    }
    
    addToFavorites = async (scope: string, data: any) => {
        /**
         * @instance
         * @async
         * @method addToFavorites
         * @memberof Providers.FavoritesProvider
         * @return { Array } User favorits
         */
        try {  
            let favorites = await this.getFavorites();
            favorites.metaValue[scope].unshift(data)
            await this.updateFavorites(favorites);
        }catch(error){
            throw error
        }
    }

    inFavorites = async (scope: string, value: any) => {
        /**
         * @instance
         * @async
         * @method inFavorites
         * @memberof Providers.FavoritesProvider
         * @return { Boolean } Favorit state
         */
        try {
            const favorites = await this.getFavorites();
            if(favorites.metaValue[scope].length){
                let result = favorites.metaValue[scope].find((element)=> {
                    return element.id === value
                });
             
                return  result ? true : false
            }else{
                return false
            }
        }catch(error){
            throw error
        }
    }

    toogleFavorites = async (scope: string, data: any) => { 
        /**
         * @instance
         * @async
         * @method toogleFavorites
         * @memberof Providers.FavoritesProvider
         * @return { Boolean } Favorit state
         */
        const favorit = await this.inFavorites(scope, data.id);
        if(favorit){
            await this.removeFavorite(scope, data.id)
            return false;
        }else{
            await this.addToFavorites(scope, data)
            return true;
        }
    }

    removeFavorite = async (scope: string, value: any) => {
        /**
         * @instance
         * @async
         * @method removeFavorite
         * @memberof Providers.FavoritesProvider
         * @return { Void } 
         */
        try {  
            const favorites = await this.getFavorites();
            const temp = favorites.metaValue[scope].filter((element)=>{
                return element.id !== value
            });
            favorites.metaValue[scope] = temp;
            await this.updateFavorites(favorites)
        }catch(error){
            throw error
        }
    }

    updateFavorites =  async (data: any) => {
        /**
         * @instance
         * @async
         * @method updateFavorites
         * @memberof Providers.FavoritesProvider
         * @return { Void } 
         */
        try {
            await Metadata.put(data, metaName);
        }catch(error){
            throw error
        }
    }

    render() {
        /**
         * @instance
         * @method render
         * @memberof Providers.FavoritesProvider
         * @return { String } JSX 
         */
        return (
            <FavoritesContext.Provider value={{
                ...this.state,
                toogleFavorites: this.toogleFavorites,
                getFavorites: this.getFavorites,
                inFavorites: this.inFavorites
            }}>
            {this.props.children}
            </FavoritesContext.Provider>
        )
    }
}

const FavoritesConsumer = FavoritesContext.Consumer

export { FavoritesProvider, FavoritesConsumer }