// @flow
import React, { Component, Fragment } from "react";
import { Segment, FormGroup, Button, Message} from "semantic-ui-react";
import { Form, Input } from "semantic-ui-react-form-validator";
import { messagesForm, messagesAccountForm, passwordMatchRegExp } from "constantes";
import { isEmptyObject, setMessage } from "tools";

import "./AccountForm.scss";

type Props = {
    account: any,
    consumers: any
};

type State = {
    awaiting: boolean,
    displayPassword: boolean,
    firstName: string,
    lastName: string,
    email: string,
    organization: string,
    address: string,
    zipCode: string,
    city: string,
    phone: string,
    specs: any,
    password: string,
    oldPassword: string;
    passwordConfirmation: string,
    message: any
};

/**
 * AccountForm
 * @author Hugo hle@deltacode.fr
 * @memberof Forms
 * @extends {React.Component}
 * @description Account form
 */
class AccountForm extends Component<Props,State> {
    
    state = {
        awaiting: false,
        displayPassword: false,
        firstName: "",
        lastName: "",
        email: "",
        organization: "",
        address: "",
        zipCode: "",
        city: "",
        phone: "",
        specs: {},
        password: "",
        oldPassword: "",
        passwordConfirmation: "",
        message: {},
    }

    componentDidMount() {
        /**
         * @instance
         * @async
         * @memberof Forms.AccountForm
         * @method componentDidMount
         * @return { Void } 
        */
        Form.addValidationRule('same', (value, field) => {
            const expectedValue = this.state[field];
            return value === expectedValue;
        });
        Form.addValidationRule('different', (value, field) => {
            const expectedValue = this.state[field];
            return value !== expectedValue;
        });
        const data = this.props.account;
        if(data && !isEmptyObject(data)){
            this.setState({
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                organization: data.organization,
                address: data.address,
                zipCode: data.zipCode,
                city: data.city,
                phone: data.phone,
                specs: data.specs
          });
        }
    }

    handleInputChange = (event:SyntheticInputEvent<>, { name, value }:any) => {
        /**
         * @instance
         * @memberof Forms.AccountForm
         * @method handleInputChange
         * @params { Object } event Event
         * @params { String } name Form name
         * @params { String } name Form value
         * @return { Void } 
        */
        this.setState({ [name]: value });
    };
    
    handlePasswordTrigger = () => {
        /**
         * @instance
         * @memberof Forms.AccountForm
         * @method handlePasswordTrigger
         * @return { Void } 
        */
        this.setState({ 
            displayPassword: !this.state.displayPassword 
        });
    }

    handleUpdateAccount = async () => {
        /**
         * @instance
         * @async
         * @memberof Forms.AccountForm
         * @method handleUpdateAccount
         * @return { Void } 
        */
        this.setState({ awaiting: true });
        let status = null;
        try {
            const data = {};
            data.firstName = this.state.firstName;
            data.lastName = this.state.lastName;
            data.email = this.state.email;
            data.organization = this.state.organization;
            data.address = this.state.address;
            data.zipCode = this.state.zipCode;
            data.city = this.state.city;
            data.phone = this.state.phone;
            data.specs = this.state.specs
        
            if( this.state.displayPassword ){
                data.oldPassword = this.state.oldPassword
                data.password = this.state.password
            }
            const { account } = this.props.consumers;
            await account.updateAccount(data);
            status = 200
        }
        catch(error) {
            status = error.status
        }
    
        await this.setState({ 
            message: setMessage(messagesAccountForm, status),
            awaiting: false, 
        });
    };

    render() {
        /**
         * @instance
         * @method render
         * @memberof Forms.AccountForm
         * @return { String } JSX 
         */
        const {
            firstName,
            lastName,
            email,
            organization,
            address,
            zipCode,
            city,
            phone,
            awaiting,
            displayPassword,
            password,
            oldPassword,
            passwordConfirmation,
            message
        } = this.state;

        return (
            <Fragment>
                { !isEmptyObject(message) && <Message color={message.color} content={message.text}/> }
                <Form className="ui form AccountForm" onSubmit={this.handleUpdateAccount}>
                <FormGroup inline>
                    <Input
                        label="Prénom"
                        placeholder="Prénom"
                        width={4}
                        name="firstName"
                        value={firstName}
                        validators={["required"]}
                        errorMessages={[messagesForm.required]}
                        onChange={this.handleInputChange}
                        className="color-gray"
                    />
                    <Input
                        label="Nom"
                        placeholder="Nom"
                        width={4}
                        name="lastName"
                        value={lastName}
                        validators={["required"]}
                        errorMessages={[messagesForm.required]}
                        onChange={this.handleInputChange}
                        className="color-gray"
                    />
                    <Input
                        label="Adresse mail"
                        placeholder="Adresse mail"
                        width={8}
                        name="email"
                        type="email"
                        value={email}
                        validators={["required", "isEmail"]}
                        errorMessages={[messagesForm.required, messagesForm.isEmail]}
                        onChange={this.handleInputChange}
                        className="color-gray"
                    />
                </FormGroup>
                <FormGroup inline>
                    <Input
                        label="Société"
                        placeholder="Société"
                        width={8}
                        name="organization"
                        value={organization}
                        validators={["required"]}
                        errorMessages={[messagesForm.required]}
                        onChange={this.handleInputChange}   
                        className="color-gray"
                    />
                    <Input
                        label="Adresse"
                        placeholder="Adresse"
                        width={8}
                        name="address"
                        value={address}
                        validators={["required"]}
                        errorMessages={[messagesForm.required]}
                        onChange={this.handleInputChange}
                        className="color-gray"
                    />
                </FormGroup>
                <FormGroup inline>
                    <Input
                        label="Code Postal"
                        placeholder="Code Postal"
                        width={8}
                        name="zipCode"
                        value={zipCode}
                        validators={['required']}
                        errorMessages={[messagesForm.required, messagesForm.isZipCode,  messagesForm.isZipCodeFull, messagesForm.isZipCodeCorrect ]}
                        onChange={this.handleInputChange}
                        className="color-gray"
                    />
                    <Input
                        label="Ville"
                        placeholder="Ville"
                        width={8}
                        name="city"
                        value={city}
                        validators={["required"]}
                        errorMessages={[messagesForm.required]}
                        onChange={this.handleInputChange}
                        className="color-gray"
                    />
                </FormGroup>
                <FormGroup inline>
                    <Input
                    fluid
                    label="Téléphone"
                    placeholder="Téléphone"
                    width={16}
                    name="phone"
                    value={phone}
                    validators={['required', 'isNumber', 'minStringLength:10','maxStringLength:10']}
                    errorMessages={[messagesForm.required, messagesForm.isPhone, messagesForm.isPhoneFull, messagesForm.isPhoneCorrect]}
                    onChange={this.handleInputChange}
                    className="color-gray"
                    />
                </FormGroup>
                {displayPassword &&
                <Fragment>
                    <FormGroup>
                        <Input
                            fluid
                            label="Ancien mot de passe"
                            placeholder="Ancien mot de passe"
                            width={8}
                            name="oldPassword"
                            type="password"
                            value={oldPassword}
                            validators={['required']}
                            errorMessages={[messagesForm.required]}
                            onChange={this.handleInputChange}
                        />
                    </FormGroup>
                    <FormGroup >
                        <Input
                            fluid
                            label="Nouveau mot de passe"
                            placeholder="Nouveau mot de passe"
                            width={8}
                            name="password"
                            type="password"
                            value={password}
                            validators={['required', 'different:oldPassword', passwordMatchRegExp]}
                            errorMessages={[messagesForm.required, messagesForm.differentPassword, messagesForm.isPassword]}
                            onChange={this.handleInputChange}
                        />
                        <Input
                            fluid
                            label="Confirmez votre mot de passe"
                            placeholder="Confirmez votre mot de passe"
                            width={8}
                            name="passwordConfirmation"
                            type="password"
                            value={passwordConfirmation}
                            validators={['required', 'same:password', passwordMatchRegExp]}
                            errorMessages={[messagesForm.required, messagesForm.matchPassword, messagesForm.isPassword]}
                            onChange={this.handleInputChange}
                        />
                    </FormGroup>
                </Fragment>
                }
                    <Button
                        basic 
                        onClick={this.handlePasswordTrigger}
                        type="button"
                        className="btn"
                        content={displayPassword ? "Annuler" : "Mettre à jour un mot de passe"}
                    />
                    <Segment basic textAlign="center">
                        <Button
                            loading={awaiting}
                            disabled={awaiting}
                            content="Mettre à jour"
                            type="submit"
                            className="btn default"
                            size="large"
                        />
                    </Segment>
                </Form>
            </Fragment>
        );
    }
}

export { AccountForm };