// @flow
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Image, Icon} from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { logoVertical } from 'constantes';

import './Header.scss'; 

type Props = {
    favorites: any,
    location: any,
    history: any,
    match: any,
    firstConnection: boolean,
    expiredToken: any
};

type State = {
    active: boolean,
    error: boolean
};

/**
 * Header Component
 * @author Tomasz tpr@deltacode.fr
 * @memberof Components
 * @extends {React.Component}
 * @description Header Menu component
 */
class WithRouterHeader extends Component<Props, State> {
    
    /**
     * @constructor
     * @param { Object } props Props
     */
    constructor(props: Props) {
        super(props)
        this.state = {
            active: false,
            error: true
        }
    }
   
    async componentDidMount(){
        /**
         * @instance
         * @async
         * @memberof Components.WithRouterHeader
         * @method componentDidMount
         * @return { Void } 
         */
        await this.getStatus();
    }

  
    async UNSAFE_componentWillReceiveProps(){
        /**
         * @instance
         * @async
         * @memberof Components.WithRouterHeader
         * @method componentWillReceiveProps
         * @return { Void } 
         */
        const expired = await this.props.expiredToken();
        if(!expired){
           await this.getStatus();
        }
    }

    getStatus = async () => {
        /**
         * @instance
         * @async
         * @method getStatus
         * @memberof Components.WithRouterHeader
         * @description Get favorit status
         * @return { Void } 
         */
        if( this.props.history.location.state){
            const { scope } = this.props.location.state;
            try{
                const id = this.props.location.state.id;
                const active = await this.props.favorites.inFavorites(scope, id); 
                await this.setState({
                    error : false,
                    active : active
                }) 
            } 
            catch(error) { 
                await this.setState({
                    error : true
                }) 
            }  
        }else{
            await this.setState({
                active : false
            });
        }
    }

    handleToogleFavorits= async () => {
        /**
         * @instance
         * @async
         * @method handleToogleFavorits
         * @memberof Components.WithRouterHeader
         * @description Update favorit status
         * @return { Void } 
         */
        const data = {
            id : this.props.location.state.id,
            idcc : this.props.location.state.idcc,
            title: this.props.location.state.title,
            description: this.props.location.state.description
        }
        const { scope } = this.props.location.state;
        await this.setState({
            active : !this.state.active
        }) 
       
        await this.props.favorites.toogleFavorites(scope, data);
    }

    handleGoBack = () => {
        /**
         * @instance
         * @method handleGoBack
         * @memberof Components.WithRouterHeader
         * @description History back
         * @return { Void } 
         */
        if(this.props.location.state &&this.props.location.state.scope === 'entreprise') this.props.history.prev ='entreprise'
        this.props.history.goBack()
    }

    render() {
        /**
         * @instance
         * @method render
         * @memberof Components.WithRouterHeader
         * @return { String } JSX 
         */
        const { firstConnection } = this.props;
        const { pathname } = this.props.location;
        const { active, error } = this.state;
        return (
            <Fragment>
               <Menu secondary fixed="top" className="Header" fluid widths={3}>
                    <Menu.Item className="menu-left">
                     {pathname !== "/"&& pathname !== "/errors"  && !firstConnection && (
                            <Icon onClick={this.handleGoBack} name="chevron left" size="large"/>
                            )
                        }
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="/"><Image size="small" src={logoVertical} /></Link>
                    </Menu.Item>
                    <Menu.Item  className="menu-right">
                        {(this.props.location.pathname.split("/")[1] === "ccn" || this.props.location.pathname.split("/")[1] === "entreprise") &&
                            <Fragment>
                                {!error &&
                                    <Icon onClick={ this.handleToogleFavorits } name="heart" color={ active ? 'red' : 'grey' } size="large"/>
                                }
                            </Fragment>
                        }
                 
                    </Menu.Item>
                </Menu>
            </Fragment>
        );
    }
}
const Header = withRouter(WithRouterHeader)
export {  Header };