// @flow
import React, { Component, Fragment } from "react";
import { Wrapper, Loading } from "components";
import { logo, messagesPasswordUpdate, messagesForm, passwordMatchRegExp  } from "constantes";
import { Link } from "react-router-dom";
import {
  Grid,
  Header,
  Button,
  Segment,
  Image,
  Message
} from "semantic-ui-react";
import { Form, Input} from "semantic-ui-react-form-validator";
import { isEmptyObject, setMessage } from "tools";


type Props = {
    consumers: any,
    hash: string
}

type State = {
    loading: boolean,
    validate: boolean,
    awaiting: boolean,
    password: string,
    passwordConfirmation: string,
    message: any
}

/**
 * PasswordUpdateForm
 * @author Tomasz tpr@deltacode.fr
 * @memberof Forms
 * @extends {React.Component}
 * @description Password update form
 */
class PasswordUpdateForm extends Component<Props, State> {
    
    state = {
        loading: false,
        validate: false,
        awaiting: false,
        password: "",
        passwordConfirmation: "",
        message: {},
    }

    componentDidMount() {
        /**
         * @instance
         * @async
         * @memberof Forms.PasswordUpdateForm
         * @method componentDidMount
         * @return { Void } 
        */
        Form.addValidationRule('same', (value, field) => {
            const expectedValue = this.state[field];
            return value === expectedValue;
        });
        this.setState({
            loading: true
      })

    }

    handleInputChange = (event:SyntheticInputEvent<>, { name, value }:any) => {
        /**
         * @instance
         * @memberof Forms.PasswordUpdateForm
         * @method handleInputChange
         * @params { Object } event Event
         * @params { String } name Form name
         * @params { String } name Form value
         * @return { Void } 
        */
        this.setState({ [name]: value });
    };

    handleUpdatePassword = async () => {
        /**
         * @instance
         * @async
         * @memberof Forms.PasswordUpdateForm
         * @method handleUpdatePassword
         * @return { Void } 
        */
        await this.setState({ 
            awaiting: true
        });
        let status = null;

        try {
            const { password } = this.props.consumers;
            const response = await password.updatePassword(this.props.hash, this.state.password);
            await this.setState({ validate: true })
            status = response.status
        } catch(error) {    
            status = error.status  
            await this.setState({
                validate: false
            });
        }

          await this.setState({ 
            awaiting: false,
            message: setMessage(messagesPasswordUpdate, status),
        });
    }

    render() {
        /**
         * @instance
         * @method render
         * @memberof Forms.PasswordUpdateForm
         * @return { String } JSX 
         */
        const {
        loading,
        awaiting,
        password,
        passwordConfirmation,
        validate,
        message,
        } = this.state;
        return (
            <Fragment>
                <Grid className="login" centered padded columns={1}>
                    <Grid.Column mobile={14} tablet={8} computer={6}>
                    <Image
                        style={{ marginTop: "1em" }}
                        src={logo}
                        size="medium"
                        centered
                     />
                    { !isEmptyObject(message) && <Message color={message.color} content={message.text}/> }
                    {loading ? (
                        <Fragment>
                            {validate ? (
                                <Fragment>
                                     <Header as="h2" textAlign="center" className="lead color-default">  
                                        Votre nouveau mot de passe a bien été changé
                                    </Header>
                                    <Segment basic textAlign="center">
                                        <Link to='/connexion'>
                                            <Button className="btn default" size="large" >
                                                Se connecter
                                            </Button>
                                        </Link>
                                    </Segment>
                                </Fragment>
                            ):(
                                <Fragment>
                                    <Header as="h2" textAlign="center" className="lead color-default">
                                        Indiquer votre nouveau mot de passe
                                    </Header>
                                    <Form onSubmit={this.handleUpdatePassword}>
                                    <Input
                                        fluid
                                        label="Nouveau mot de passe"
                                        placeholder="Nouveau mot de passe"
                                        size="large"
                                        width={16}
                                        name="password"
                                        type="password"
                                        value={password}
                                        validators={[ "required", passwordMatchRegExp ]}
                                        errorMessages={[messagesForm.required, messagesForm.isPassword]}
                                        onChange={this.handleInputChange}
                                    />
                                    <Input
                                        fluid
                                        label="Confirmez votre mot de passe"
                                        placeholder="Confirmez votre mot de passe"
                                        size="large"
                                        width={16}
                                        name="passwordConfirmation"
                                        type="password"
                                        value={passwordConfirmation}
                                        validators={['required', 'same:password', passwordMatchRegExp]}
                                        errorMessages={[messagesForm.required, messagesForm.matchPassword, messagesForm.isPassword]}
                                        onChange={this.handleInputChange}
                                    />
                                    <Segment basic textAlign="center">
                                        <Button 
                                            loading={awaiting}
                                            disabled={awaiting}
                                            className="btn default" 
                                            size="large" type="submit" >
                                            Valider
                                        </Button>
                                    </Segment>
                                </Form>
                            </Fragment>
                            )}
                        </Fragment>
                        ):(
                            <Wrapper name="block">
                                <Loading title="Chargement" />
                            </Wrapper>
                        )}
                    </Grid.Column>
                </Grid>
            </Fragment>
        );
    }
}

export { PasswordUpdateForm };