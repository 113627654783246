// @flow
import React  from 'react';
import { Switch} from "react-router-dom";
import { ProtectedRoute, ConsumerRoute } from 'routes';

import { 
    ConnectionConsumer,
    AccountConsumer,
    UserConsumer,
    FavoritesConsumer,
    ReviewsConsumer,
    CcnConsumer, 
    CompaniesConsumer,
    ChatBotConsumer,
    HistoryConsumer,
    MailConsumer,
    PasswordConsumer
    } from 'providers';
    
import { 
    Activate,
    Dashboard,
    SignIn, 
    Finder, 
    Ccn, 
    CcnDetail, 
    Favorites, 
    Settings, 
    Account,
    Users,
    User,
    Companies, 
    Company, 
    Articles, 
    Article,
    Contact,
    ChatBot,
    PasswordUpdate
    } from 'containers';
    
const routes = {
    protected: [
        { 
            path: "/ccn/:id/detail", 
            component: CcnDetail,
            consumers: [
                { name: 'ccns', consumer: CcnConsumer}
            ]
        },
        { 
            path: "/ccn/:id", 
            component: Ccn, 
            consumers: [
                { name: 'ccns', consumer: CcnConsumer},
                { name: 'history', consumer: HistoryConsumer}
            ]
        },
        { 
            path: "/recherche/:scope",
            component: Finder, 
            consumers: [
                { name: 'ccns', consumer: CcnConsumer},
                { name: 'history', consumer: HistoryConsumer}
            ]
        },
        { 
            path: "/entreprises/:id", 
            component: Companies, 
            consumers: [
                { name: 'companies', consumer: CompaniesConsumer},
                { name: 'ccns', consumer: CcnConsumer},
                { name: 'history', consumer: HistoryConsumer}
            ]
        },
        { 
            path: "/entreprise/:siren", 
            component: Company,
            consumers: [
                { name: 'companies', consumer: CompaniesConsumer},
                { name: 'ccns', consumer: CcnConsumer}
            ]
        },
        { 
            path: "/favoris",
            component: Favorites, 
            consumers: [
                { name: 'reviews', consumer: ReviewsConsumer},
                { name: 'favorites', consumer: FavoritesConsumer}
            ]
        },
        { 
            path: "/parametres/compte",
            component: Account, 
            consumers: [
                { name: 'account', consumer: AccountConsumer}
            ]
        },
        {  
            path: "/parametres/utilisateurs",
            component: Users, 
            consumers: [
                { name: 'user', consumer: UserConsumer}
            ]
        },
        { 
            path: "/parametres/utilisateur",
            component: User,
            consumers: [
                { name: 'user', consumer: UserConsumer}
            ]
        },
        { 
            path: "/parametres/contact",
            component: Contact, 
            consumers: [
                { name: 'mail', consumer: MailConsumer}
            ]
        },
        { 
            path: "/parametres",
            component: Settings,
            consumers: [
                { name: 'account', consumer: AccountConsumer},
                { name: 'user', consumer: UserConsumer},
                { name: 'connection', consumer: ConnectionConsumer}
            ]
        },
        { 
            path: "/chatbot", 
            component: ChatBot, 
            consumers: [
                { name: 'chatbot', consumer: ChatBotConsumer},
                { name: 'mail', consumer: MailConsumer}
            ]
        },
        { 
            path: "/articles",
            component: Articles
        },
        { 
            path: "/article",
            component: Article
        },
        { 
            path: "/",
            component: Dashboard
        },
    ],
    consumers :  [
        { 
            path: "/motdepasse-oublie/:hash",
            component: PasswordUpdate, 
            consumers: [
                { name: 'password', consumer: PasswordConsumer}
            ]
        },
        { 
            path: "/activation/:hash",
            component: Activate,
            consumers: [
                { name: 'connection', consumer: ConnectionConsumer}
            ]
        },
        { 
            path: "/", 
            component: SignIn, 
            consumers: [
                { name: 'connection', consumer: ConnectionConsumer}, 
                { name: 'password', consumer: PasswordConsumer}
            ]
        }
    ]
}

type Props = {
    component: any,
    consumers?:  Array<{| consumer: any, name: string |}>,
    authenticated: boolean,
    firstConnection: boolean,
    owner: boolean,
    expiredToken: any,
    logout: any
}

type State = {
    expired: boolean
}


/**
 * PrivateRoutes
 * @author Tomasz tpr@deltacode.fr
 * @memberof Routes
 * @description PrivateRoutes Component
 */
class PrivateRoutes extends React.Component<Props,State> {
    
    render() {
        /**
         * @instance
         * @method render
         * @memberof Routes.PrivateRoutes
         * @return { String } JSX 
         */
        const  { authenticated, owner, firstConnection, expiredToken, logout} = this.props
        return (
            <Switch>
                {routes.protected.map((route, index) => {
                    return <ProtectedRoute
                            key={index}
                            path={route.path} 
                            component={route.component}
                            consumers={route.consumers ? route.consumers : [] }
                            authenticated={authenticated}
                            owner={owner}
                            firstConnection={firstConnection}
                            expiredToken={expiredToken}
                            logout={logout}
                        /> 
                    })}
            </Switch>
        );
    }
}

/**
 * PublicRoutes
 * @author Tomasz tpr@deltacode.fr
 * @description PublicRoutes Component
 */
class PublicRoutes extends React.Component<{}> {

    render() {
        /**
         * @instance
         * @method render
         * @memberof Routes.PublicRoutes
         * @return { String } JSX 
         */
        return (
            <Switch>
                {routes.consumers.map((route, index) => {
                    return <ConsumerRoute
                        key={index}
                        path={route.path} 
                        component={route.component}
                        consumers= {route.consumers}
                    /> 
                })}                
            </Switch>
        );
    }
}
export { PrivateRoutes, PublicRoutes } 