// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {Wrapper,  Loading } from "components";
import { logo } from "constantes";
import {
    Grid,
    Header,
    Icon,
    Button,
    Container,
    Segment,
    Image
} from "semantic-ui-react";

import "./Activate.scss";

type Props = {
    match: any,
    history: any,
    consumers: any
};

type State = {
    validate: boolean,
    loading: boolean
}

/**
 * Activate
 * @author Romain rsa@deltacode.fr
 * @memberof Containers
 * @extends {React.Component}
 * @description Activate container
 */

class Activate extends Component<Props, State> {

    constructor(props: Props) {
        /**
         * @constructor
         */
        super(props)
        this.state = {
            validate: false,
            loading: true
        }
    }

    async componentDidMount() {
        /**
         * @instance
         * @async
         * @memberof Containers.Activate
         * @method componentDidMount
         * @return { Void } 
         */
        try {
            const { connection } = this.props.consumers;
            await connection.activate(this.props.match.params.hash);
            await this.setState({
                loading: true,
                validate: true
            });
        }
        catch(error) {  
            this.props.history.push('/connexion');
        }
    }

    render() {
        /**
         * @instance
         * @method render
         * @memberof Containers.Activate
         * @return { String } JSX 
         */
        const {loading, validate } = this.state;
        return (
            <Grid className="Activate login" centered padded columns={1}>
                <Grid.Column mobile={14} tablet={8} computer={6}>
                    <Image
                        style={{ marginTop: "1em" }}
                        src={logo}
                        size="medium"
                        centered
                    />
                    <Header as="h2" textAlign="center" className="lead color-default">
                        Votre compte a bien été activé.
                    </Header>
                    {loading && validate  ? (
                        <Wrapper>
                            <Container textAlign="center">
                                <Icon size="huge" color="green" name="check circle" />
                                <Segment basic textAlign="center">
                                    <Link to="/connexion">
                                        <Button className="btn default" size="large">
                                        Se connecter
                                        </Button>
                                    </Link>
                                </Segment>
                            </Container>
                        </Wrapper>
                    ) : (
                    <Wrapper>
                        <Loading title="Chargement" />
                    </Wrapper>
                    )}
                </Grid.Column>
            </Grid>
        );
   }
}

export { Activate };