 // @flow
import { checkStatus, checkError } from 'tools';

/**
 * Password service
 * @author Tomasz tpr@deltacode.fr
 * @memberof Password
 * @description User password service
 */
class Password  {

    static url:any = process.env.REACT_APP_API_URL;
    static entrypoints:any = {
        forget: 'forget',
        update: 'update'
    }

    static updatePassword = async (hash:string, password:string) => {
        /**
         * Update password
         *
         * @async
         * @method updatePassword
         * @memberof Services.Password
         * @param hash Hash for update password
         * @param password New password
         * @return { String } Updated account
        */
        const data = {
            password: password
        }
        try {
            const response = await fetch(`${this.url}/password/${this.entrypoints.update}/${hash}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
                mode: 'cors',
            });
             return checkStatus(response);
        } catch(error) {
            throw checkError(error);
      }
    }

    static forgetPassword = async (email:string) => {
        /**
         * Forget password
         *
         * @async
         * @method forgetPassword
         * @memberof Services.Password
         * @param email User mail
         * @return { Object } Mail request 
        */
        const data = {
            sender: "fasstccn",
            recipient: email,
            template: "forgetPassword"
        }
        try {
            const response = await fetch(`${this.url}/password/${this.entrypoints.forget}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data),
                mode: 'cors'
            });
            return checkStatus(response);
        } catch(error) {
            throw checkError(error);
        }
    }
}
export  { Password };