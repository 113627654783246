// @flow
import React from 'react';
import { Account } from 'services/accountService';

const AccountContext = React.createContext<any>();

type Props = {
    children: any
}

type State = {
    firstConnection: boolean
};

/**
 * AccountProvider
 * @author Tomasz tpr@deltacode.fr
 * @memberof Providers
 * @extends {React.Component}
 * @description Account provider
 */
class AccountProvider extends React.Component<Props,State> {

    /**
     * @constructor
     * @param { Object } props Props
     */
    constructor(props: Props) {
        super(props)
        this.state = {
            firstConnection: true
        }
    }

    getAccount = async () => {
        /**
         * @instance
         * @async
         * @method getAccount
         * @memberof Providers.AccountProvider
         * @return { Object } Account data 
         */
        try {
            const response = await Account.getAccount();
            const payload = await response.json();
            return payload
        }
        catch(error) {
            throw error;
        }
    }

    updateAccount = async (data:any) => {
        /**
         * @instance
         * @async
         * @method updateAccount
         * @memberof Providers.AccountProvider
         * @param { Object } data Form data
         * @return { String } Update account message
         */
        try {
            const response = await Account.updateAccount(data);
            const payload = await response.json();
            return payload
        }
        catch(error) {
            throw error
        }
    }

    render() {
        /**
         * @instance
         * @method render
         * @memberof Providers.AccountProvider
         * @return { String } JSX 
         */
        return (
            <AccountContext.Provider
                value={{
                    ...this.state,
                    getAccount: this.getAccount,
                    updateAccount: this.updateAccount,
                }}>
                {this.props.children}
            </AccountContext.Provider>
        )
    }
}

const AccountConsumer = AccountContext.Consumer

export { AccountProvider, AccountConsumer }