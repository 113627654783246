// @flow
import React, { Component } from "react";
import { ContactForm } from "forms";
import { Transition, Header, Grid } from "semantic-ui-react";
import { Wrapper, Loading } from "components";

import "./Contact.scss";

type Props = {
    consumers: any
}

type State = {
    visible: boolean,
    loading: boolean
};

/**
 * Contact
 * @author Hugo hle@deltacode.fr
 * @memberof Containers
 * @extends {React.Component}
 * @description Contact container
 */
class Contact extends Component<Props,State> {
    
    /**
     * @constructor
     * @param { Object } props Props
    */
    constructor(props: Props) {
        super(props)
        this.state = {
            visible: false,
            loading: false
        }
    }
  
    async componentDidMount() {
        /**
         * @instance
         * @async
         * @memberof Containers.Contact
         * @method componentDidMount
         * @return { Void } 
        */
        await this.setState({ loading: true, visible: true });
    }
    
    render() {
        /**
         * @instance
         * @method render
         * @memberof Containers.Contact
         * @return { String } JSX 
        */
        const { loading, visible } = this.state;
        const { consumers } = this.props

        return (
            <Grid className="Contact page bg-gradient">
                <Grid.Row>
                <Grid.Column>
                    <Transition visible={visible} animation="fade up" duration={500}>
                    <Header as="h2" textAlign="center" className="lead color-grey">
                        Contact
                    </Header>
                    </Transition>
                    {loading ? (
                    <Wrapper name="block">
                        <ContactForm consumers={consumers}/>
                    </Wrapper>
                    ) : (
                    <Wrapper name="block">
                        <Loading title="Chargement" />
                    </Wrapper>
                    )}
                </Grid.Column>
                </Grid.Row>
            </Grid>  
        );
    }
}

export { Contact };
