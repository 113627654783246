// @flow
import React, { Component } from "react";
import { Transition, Header, Grid, Message } from "semantic-ui-react";
import { Wrapper, Loading } from "components";
import { UserForm } from "forms";
import { messagesUser } from "constantes";
import { isEmptyObject, setMessage } from "tools";

import "./User.scss";

type Props = {
    consumers: any
}

type State = {
    loading: boolean,
    visible: boolean,
    user: any,
    message: any
}

/**
 * User
 * @author Hugo hle@deltacode.fr
 * @memberof Containers
 * @extends {React.Component}
 * @description User container
*/
class User extends Component<Props, State> {

    /**
     * @constructor
     * @param { Object } props Props
    */
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            visible: false,
            user: {},
            message: {}
        }
    }
    
    async componentDidMount() {
        /**
         * @instance
         * @async
         * @memberof Containers.User
         * @method componentDidMount
         * @return { Void } 
        */
        await this.setState({ visible: true });
        try{
            const { user } = this.props.consumers;
            const response = await user.getUser();
            await this.setState({ 
                user: response, 
                loading: true 
            });
        } catch(error) {
            await this.setState({ 
                message: setMessage(messagesUser, error.status)
            });
        }
    }

    render() {
        /**
         * @instance
         * @method render
         * @memberof Containers.User
         * @return { String } JSX 
        */
        const { loading, visible, user, message } = this.state;
        const { consumers } = this.props;
        return (
            <Grid className="User page bg-gradient">
                <Grid.Row>
                    <Grid.Column>
                        <Transition visible={visible} animation="fade up" duration={500}>
                            <Header as="h2" textAlign="center" className="lead">
                            Mon compte
                            </Header>
                        </Transition>
                        {loading ? (
                            <Wrapper name="block">
                                <Header className="color-gray" as="h3" content="Informations utilisateur"/>
                                { !isEmptyObject(message) && <Message color={message.color} content={message.text}/> }
                                <UserForm consumers={consumers} user={user} />
                            </Wrapper> 
                        ) : (
                            <Wrapper name="block">
                                <Loading title="Chargement" />
                            </Wrapper>
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>  
        )
    }
}

export { User };