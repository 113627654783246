// @flow
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Wrapper, Loading } from "components";
import { Container, Header, Icon, Button, Message } from 'semantic-ui-react';
import { strings, messagesHistory } from 'constantes';
import { setMessage, capitalize } from "tools";

import './History.scss';

type Props = {
    scope: string,
    getHistory: any,
    clearHistory: any
}

type State = {
    loading: boolean,
    error: boolean,
    scope: string,
    history: any,
    message: any
}

/**
 * History
 * @author Tomasz tpr@deltacode.fr
 * @memberof Components
 * @extends {React.Component}
 * @description History component
 */
class History extends Component<Props, State> {

    /**
     * @constructor
     * @param { Object } props Props
     */
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: false,
            error: false,
            scope: "",
            history : {},
            message:{}
        }
    }
 
    async componentDidMount() {
        /**
         * @instance
         * @async
         * @memberof Components.History
         * @method componentDidMount
         * @return { Void } 
         */
        try {
            const history = await this.props.getHistory();
            await this.setState({
                scope: this.props.scope,
                history: history,
                error: false,
                loading: true
            })
        }
        catch(error) {
            await this.setState({
                error: true,
                message: setMessage(messagesHistory, error.status)
            })
        }
    }

    handleClearHistory = async () => {
        /**
         * @instance
         * @async
         * @method handleClearHistory
         * @memberof Components.History
         * @description Clean history
         * @return { Void } 
         */
        const temp = this.state.history;
        temp.metaValue[this.state.scope] = [];
        await this.setState({
            history : temp
        })
        await this.props.clearHistory(this.state.history)
    }

    render() {
        /**
         * @instance
         * @method render
         * @memberof Components.History
         * @return { String } JSX 
         */
        const { loading, error, scope, history , message} = this.state;
        return (
            <Fragment>
                { loading ? (
                    <Fragment>
                       <Container textAlign='center'>
                            <Icon circular name={scope === 'ccn' ? 'search': 'building'} size='big'/>
                        </Container>
                        <Header 
                            as='h3' 
                            content={history.metaValue[scope].length > 0  ? 'Mes dernières recherches' : 'C’est votre première recherche ?'} 
                            textAlign='center'
                            className='color-default'
                        />
                        {error ? (<Message color={message.color} content={message.text}/>):(
                            <Fragment>
                            {!history.metaValue[scope].length > 0
                                ?
                                <Container textAlign='center'>
                                    {strings.history[scope].title}
                                </Container>
                                : history.metaValue[scope].map((item, index) => {    
                     
                                    const params = {idcc: item.idcc, id: item.id, title: item.title, description: item.description, scope: scope}
                                    return(
                                        <Link key={index} className="link small"  to={{pathname:`/${scope}/${item.id}`, state: params}}>
                                            <Wrapper name="color-default-light header">IDCC {item.idcc} - {capitalize(item.description)}</Wrapper>
                                            <Wrapper name="content">{capitalize(item.title)} </Wrapper>
                                        </Link>
                                        )
                                    }
                                )
                            }
                            <Container textAlign='center'>
                                {history.metaValue[scope].length ?
                                    <Button basic onClick={this.handleClearHistory} content='Effacer l’historique' size='small' />
                                : null
                                }
                            </Container>
                            </Fragment>
                         )}
                    </Fragment>
                ) : (
                    <Fragment>
                        { !error && <Loading title="Chargement"/>   }
                    </Fragment>
                ) }
            </Fragment>
        );
    }
}

export { History }