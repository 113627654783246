// @flow
import React, { Component } from 'react';
import { Wrapper } from "components";
import { 
    logo, 
    messagesForm, 
    messagesforgetPassword,
    messagesSignIn
} from 'constantes';
import { 
    Form, 
    Input
} from 'semantic-ui-react-form-validator';
import { 
    Grid, 
    Segment,
    Header, 
    Image, 
    Button, 
    Icon, 
    Modal,
    Container,
    Message
} from 'semantic-ui-react';
import { setMessage } from "tools";

import "./SignInForm.scss";

type Props = {
    consumers: any
}

type State = {
    awaitingSignIn: boolean,
    awaitingForgetPassword: boolean,
    isPasswordForm: boolean,
    isLoginForm: boolean,
    error: boolean,
    email: string,
    password: string,
    forget: string,
    message: any
}

/**
 * SignInForm 
 * @author Tomasz tpr@deltacode.fr
 * @memberof Forms
 * @extends {React.Component}
 * @description SignInForm Form 
 */
class SignInForm extends Component<Props, State> {

    /**
     * @constructor
     * @param { Object } props Props
     */
    constructor(props: Props) {
        super(props)
        this.state = {
            email: '',
            password: '',
            forget: '',
            message: {},
            awaitingSignIn: false,
            awaitingForgetPassword: false,
            isPasswordForm: false,
            isLoginForm: false,
            error: false
        }
    }

    handleInputChange = (event:SyntheticInputEvent<>, { name, value }:any) => {
        /**
         * @instance
         * @memberof Forms.SignInForm
         * @method handleInputChange
         * @params { Object } event Event
         * @params { String } name Form name
         * @params { String } name Form value
         * @return { Void } 
        */
        this.setState({ [name]: value });
    };

    handleSubmit = async () => { 
        /**
         * @instance
         * @async
         * @memberof Forms.SignInForm
         * @method handleSubmit
         * @return { Void } 
        */
        this.setState({ awaitingSignIn: true });
        try {
            await this.props.consumers.connection.login(this.state.email, this.state.password);
        } catch(error) { 
            this.setState({ 
                awaitingSignIn: false,
                error: true,
                isLoginForm: true, 
                message: setMessage(messagesSignIn, error.status)
            }) 
        }
    };

    handleForgetPassword = async () => {
        /**
         * @instance
         * @async
         * @memberof Forms.SignInForm
         * @method handleForgetPassword
         * @return { Void } 
        */
        await this.setState({ awaitingForgetPassword: true });
        let status = null;
        try {
            const { password } = this.props.consumers;
            const response = await password.forgetPassword(this.state.forget);
            status = response.status;
          } catch(error) {
            status = error.status
        }
        await this.setState({ 
            message:  setMessage(messagesforgetPassword, status), 
            isPasswordForm: true, 
            awaitingForgetPassword  : false 
        });
    } 

    render() {
        /**
         * @instance
         * @method render
         * @memberof Forms.SignInForm
         * @return { String } JSX 
         */
        const { isLoginForm, awaitingSignIn, isPasswordForm, awaitingForgetPassword, message, email, password, forget} = this.state
        return (
            <Grid className="SignInForm" centered padded columns={1}>
                <Grid.Column mobile={14} tablet={8} computer={6}>
                    <Image style={{ marginTop: '3em' }} src={logo} size='medium' centered/>                
                    <Header as='h2' textAlign='center' className='lead color-default'>
                        Connectez-vous
                    </Header>
                        <Form onSubmit={this.handleSubmit}>
                            <Segment basic>
                                {isLoginForm && <Message color={message.color} content={message.text}/>}
                                <Input 
                                    label="E-mail"
                                    placeholder='E-mail' 
                                    size='large'
                                    width={16} 
                                    name='email' 
                                    value={email}
                                    validators={['required', 'isEmail']} 
                                    errorMessages={[messagesForm.required, messagesForm.isEmail]}
                                    onChange={this.handleInputChange} 
                                />
                                <Input 
                                    label="Mot de passe"
                                    placeholder='Mot de passe' 
                                    size='large'
                                    width={16}
                                    type="password"
                                    name='password'
                                    value={password}
                                    validators={['required']}
                                    errorMessages={[messagesForm.required]}
                                    onChange={this.handleInputChange}
                                />
                                <Segment basic textAlign='center'>
                                    <Button loading={awaitingSignIn} disabled={awaitingSignIn} className='btn default' type='submit' size='large' animated>
                                        <Button.Content visible>Connexion</Button.Content>
                                        <Button.Content hidden>
                                            <Icon name='arrow right' />
                                        </Button.Content>
                                    </Button>
                                </Segment>
                            </Segment>
                        </Form> 
                        <Segment style={{ marginTop: '0' }} basic textAlign='center'>
                        <Modal
                            size='tiny'
                            trigger={<Button size='tiny' basic>Mot de passe oublié ?</Button >}
                            centered={false}
                            header={{ content: 'Mot de passe oublié ?', className: 'color-default'}}
                            content={{content: 
                            <Container>
                                {isPasswordForm  && <Message color={message.color} content={message.text}/>}
                                <Form onSubmit={this.handleForgetPassword}>
                                    <Input  
                                        label="E-mail"
                                        placeholder='E-mail' 
                                        width={16}
                                        size='large'
                                        type='email'
                                        name='forget' 
                                        value={forget} 
                                        validators={['required', 'isEmail']} 
                                        errorMessages={[messagesForm.required, messagesForm.isEmail]}
                                        onChange={this.handleInputChange} 
                                    />
                                    <Button loading={awaitingForgetPassword} disabled={awaitingForgetPassword} className='btn default' >Envoyer</Button>
                                </Form >
                            </Container>
                            }}
                            actions={[{ 
                                key: 'send',
                                content: '  Fermer',
                            }]}
                        />
                    </Segment>              
                    <Segment basic textAlign="center">
                        <a className="link medium center" href="https://www.fasst.io">
                        <Button basic size="small" >Pour plus d'information, rendez vous sur fasst.io</Button>
                        </a>
                        <Wrapper name="copyright"> Copyright {new Date().getFullYear() } Fasst, tous droits réservés.</Wrapper>
                    </Segment>
                </Grid.Column>
            </Grid>
        )
    }
}

export { SignInForm }