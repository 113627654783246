// @flow
import React from 'react';
import { Review } from 'services/reviewService';
import { Metadata } from 'services/metadataService';
import { arrayFirst, getTimstamp } from 'tools';

const ReviewsContext = React.createContext<any>();

const metaFavorites= 'ernest-favorites';
const metaUpdates = 'ernest-updates';
const limit = 25;

type Props = {
    children:any
}

type State = {
    reviews: Array<any>,
    metaUpdate: any,
    updates: Array<any>,
    updatesCount: number
};

/**
 * ReviewsProvider
 * @author Tomasz tpr@deltacode.fr
 * @memberof Providers
 * @extends {React.Component}
 * @description Reviews provider
 */
class ReviewsProvider extends React.Component<Props,State> {

    /**
     * @constructor
     * @param { Object } props Props
     */
    constructor(props: Props) {
        super(props)
        this.state = {
            reviews: [],
            metaUpdate: {},
            updates:[],
            updatesCount: 0
        }
    }

    async componentDidMount(){
        /**
         * @instance
         * @async
         * @method componentDidMount
         * @memberof Providers.ConnectionProvider
         * @return { Void } 
         */
        await this.getReviews();
        await this.getUpdates();
        await this.hasUpdates();
        await this.getCount();
    } 

    hasUpdates =  async () =>{
        /**
         * @instance
         * @async
         * @method hasUpdates
         * @memberof Providers.ReviewsProvider
         * @return { Void } 
         */
        const {reviews, updates, metaUpdate} = this.state;
        const temp = [] ;
        reviews.forEach((item:any):void => {
            const diff = item.reviews.filter(review => review.created > metaUpdate.metaValue.updateAt)
                 
            if(diff.length) {
                const current = updates.find((elm)=>  elm.ref === item.ref ) 
                if(current){
                    current.reviews =  diff.map(itm => ({
                        ...current.reviews.find((item) => (item.ref === itm.ref) && item),
                        ...itm
                    }));
                    temp.push(current)
                }else{
                    temp.push({ ref: item.ref, reviews: diff})
                }
            }   
        });
        
        const update = {
            metaKey: metaUpdates,
            metaValue: {data: temp, updateAt: metaUpdate.metaValue.updateAt},
            mid: metaUpdate.mid,
            uid: metaUpdate.uid
        }

        await Metadata.put(update, metaUpdates);
        await this.setState({ updates: temp})
    }

    getUpdates =  async () => {
        /**
         * @instance
         * @async
         * @method getUpdates
         * @memberof Providers.ReviewsProvider
         * @return { Array } Updates
         */
        const response =  await Metadata.get(metaUpdates);
        let update = await response.json()
        if( !update.length ){
            const initialize = await Metadata.post({updateAt: getTimstamp(), data: []}, metaUpdates);
            update = await initialize.json();
        }else{
            update = arrayFirst(update)
        }
        const updates = update.metaValue.data
        await this.setState({ 
            metaUpdate: update,
            updates: updates
        });
        return updates
    }

    getReviews = async () => {
        /**
         * @instance
         * @async
         * @method getReviews
         * @memberof Providers.ReviewsProvider
         * @return { Array } Reviews 
         */
        let reviews = [];
        const favorites = await Metadata.get(metaFavorites);
        const result =  arrayFirst(await favorites.json())
        if(result && result.metaValue.ccn.length){
            const refs = 
                    result
                    .metaValue.ccn
                .map(
                    i => i.id
            ).join();
            const response = await Review.get(`values=${refs}&limit=${limit}`);
            reviews = await response.json();
            await this.setState({ reviews: reviews})            
        }
        return reviews
       
    }

    confirmUpdates = async () => {
        /**
         * @instance
         * @async
         * @method viewUpdates
         * @memberof Providers.ReviewsProvider
         * @return { Void } 
         */
        const { metaUpdate} = this.state;
        const update =  {
            metaKey: metaUpdates,
            metaValue: {data: [], updateAt: getTimstamp()},
            mid: metaUpdate.mid,
            uid: metaUpdate.uid
        }
        await this.setState({ updates: [], updatesCount :0})
        await  Metadata.put(update, metaUpdates);
    }

    getCount =  async () => {
        /**
         * @instance
         * @async
         * @method getCount
         * @memberof Providers.ReviewsProvider
         * @return { Number } Updates count
         */
     
        const count = this.state.updates.reduce((acc, it) => [...acc, ...it.reviews], [])
        await this.setState({ updatesCount : count.length })
        return count.length
    }

    render() {
        /**
         * @instance
         * @method render
         * @memberof Providers.ReviewsProvider
         * @return { String } JSX 
         */
        return (
            <ReviewsContext.Provider value={{
                ...this.state,
                getReviews: this.getReviews,
                getUpdates: this.getUpdates,
                confirmUpdates: this.confirmUpdates,
                getCount: this.getCount
            }}>
            {this.props.children}
            </ReviewsContext.Provider>
        )
    }
}

const ReviewsConsumer = ReviewsContext.Consumer

export { ReviewsProvider, ReviewsConsumer }