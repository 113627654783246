// @flow
import React, { Component, Fragment } from "react";
import { Segment, FormGroup, Button, Message } from "semantic-ui-react";
import { Form, Input } from "semantic-ui-react-form-validator";
import { messagesForm, messagesUserForm, passwordMatchRegExp } from "constantes";
import { isEmptyObject, setMessage } from "tools";

import "./UserForm.scss";

type Props = {
    user: any,
    consumers: any
}

type State = {
    awaiting: boolean,
    displayPassword: boolean,
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    oldPassword: string,
    passwordConfirmation: string,
    message: any
};

/**
 * UserForm
 * @author Hugo hle@deltacode.fr
 * @memberof Forms
 * @extends {React.Component}
 * @description User form
 */
class UserForm extends Component<Props, State> {
    
   state = {
        awaiting: false,
        displayPassword: false,
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        oldPassword: "",
        passwordConfirmation: "",
        message: {}
    }

    componentDidMount() {
        /**
         * @instance
         * @async
         * @memberof Forms.UserForm
         * @method componentDidMount
         * @return { Void } 
        */
        Form.addValidationRule('same', (value, field) => {
            const expectedValue = this.state[field];
            return value === expectedValue;
        });
        Form.addValidationRule('different', (value, field) => {
            const expectedValue = this.state[field];
            return value !== expectedValue;
        });
        const user = this.props.user;
        if(user && !isEmptyObject(user)){
          this.setState({
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email
          });
        }
    }

    handleInputChange = (event:SyntheticInputEvent<>, { name, value }:any) => {
        /**
         * @instance
         * @memberof Forms.UserForm
         * @method handleInputChange
         * @params { Object } event Event
         * @params { String } name Form name
         * @params { String } name Form value
         * @return { Void } 
        */
        this.setState({ [name]: value });
    };

    handlePasswordTrigger = () => {
        /**
         * @instance
         * @memberof Forms.UserForm
         * @method handlePasswordTrigger
         * @return { Void } 
        */
        this.setState({ 
            displayPassword: !this.state.displayPassword 
        });
    }

    handleUpdateUser = async () => {
        /**
         * @instance
         * @async
         * @memberof Forms.UserForm
         * @method handleUpdateUser
         * @return { Void } 
        */
        await this.setState({ 
            awaiting: true
        });

        let status = null;

        try {
            const data = {};
            data.firstName = this.state.firstName;
            data.lastName = this.state.lastName;
            data.email = this.state.email;

            if( this.state.displayPassword ){
                data.password = this.state.password
                data.oldPassword = this.state.oldPassword
            }
            const { user } = this.props.consumers;
            const response = await user.updateUser(data);
            status = response.status
        }
        catch(error) {
            status = error.status
        }
        await this.setState({ 
            awaiting: false,
            message: setMessage(messagesUserForm, status),
        });
    };

    render() {
        /**
         * @instance
         * @method render
         * @memberof Forms.UserForm
         * @return { String } JSX 
         */
        const { 
            awaiting,
            displayPassword,
            firstName, 
            lastName, 
            email, 
            password,
            oldPassword,
            passwordConfirmation, 
            message 
        } = this.state;

        return (
            <Fragment>
                { !isEmptyObject(message) && <Message color={message.color} content={message.text}/> }
                <Form className="ui form UserForm" onSubmit={this.handleUpdateUser}>
                    <FormGroup inline>
                        <Input
                            label="Prénom"
                            placeholder="Prénom"
                            width={4}
                            name="firstName"
                            value={firstName}
                            validators={["required"]}
                            errorMessages={[messagesForm.required]}
                            onChange={this.handleInputChange}   
                            className="color-gray"
                        />
                        <Input
                            label="Nom"
                            placeholder="Nom"
                            width={4}
                            name="lastName"
                            value={lastName}
                            validators={["required"]}
                            errorMessages={[messagesForm.required]}
                            onChange={this.handleInputChange}
                            className="color-gray"
                        />
                        <Input
                            label="Adresse mail"
                            placeholder="Adresse mail"
                            width={8}
                            name="email"
                            type="email"
                            value={email}
                            validators={["required", "isEmail"]}
                            errorMessages={[messagesForm.required, messagesForm.isEmail]}
                            onChange={this.handleInputChange}
                            className="color-gray"
                        />
                    </FormGroup>
                    {displayPassword &&
                    <Fragment>
                        <FormGroup >
                            <Input
                                fluid
                                label="Ancien mot de passe"
                                placeholder="Ancien mot de passe"
                                width={8}
                                name="oldPassword"
                                type="password"
                                value={oldPassword}
                                validators={['required']}
                                errorMessages={[messagesForm.required]}
                                onChange={this.handleInputChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Input
                                fluid
                                label="Nouveau mot de passe"
                                placeholder="Nouveau mot de passe"
                                width={8}
                                name="password"
                                type="password"
                                value={password}
                                validators={['required', 'different:oldPassword', passwordMatchRegExp]}
                                errorMessages={[messagesForm.required, messagesForm.differentPassword, messagesForm.isPassword]}
                                onChange={this.handleInputChange}
                            />
                            <Input
                                fluid
                                label="Confirmez votre mot de passe"
                                placeholder="Confirmez votre mot de passe"
                                width={8}
                                name="passwordConfirmation"
                                type="password"
                                value={passwordConfirmation}
                                validators={['required', 'same:password', passwordMatchRegExp]}
                                errorMessages={[messagesForm.required, messagesForm.matchPassword, messagesForm.isPassword]}
                                onChange={this.handleInputChange}
                            />
                        </FormGroup>
                    </Fragment>
                    }
                    <Button
                        basic 
                        onClick={this.handlePasswordTrigger}
                        type="button"
                        className="btn"
                        content={displayPassword ? "Annuler": "Mettre à jour mot de passe"}
                    />
                    <Segment basic textAlign="center">
                    <Button
                        loading={awaiting}
                        disabled={awaiting}
                        type="submit"
                        className="btn default"
                        size="large"
                        content="Mettre à jour"
                    />
                    </Segment>
                </Form>
            </Fragment>

        );
    }
}

export { UserForm };