// @flow
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Icon, Label } from 'semantic-ui-react';
import { ReviewsConsumer } from 'providers';

import './Footer.scss';

/**
 * Footer
 * @author Tomasz tpr@deltacode.fr
 * @memberof Components
 * @extends {React.Component}
 * @description Footer component
 */
class Footer extends Component<{}> {

    render() {
        /**
         * @instance
         * @method render
         * @memberof Components.Footer
         * @return { String } JSX 
         */
  
        return (
            <Menu fixed="bottom" size='large' className="Footer" secondary fluid widths={4}>
                <Menu.Item> 
                    <Link to="/">
                        <Icon name='home' size='large'/>
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="/favoris">
                        <ReviewsConsumer>{state => (
                            <Fragment>
                            { state.updatesCount ? (
                            <Label circular floating size="tiny" color="red">
                                {state.updatesCount}  
                            </Label>):null} 
                            </Fragment>
                        )}
                        </ReviewsConsumer>
                        <Icon name='star' size='large'/>
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="/articles">
                        <Icon name='book' size='large'/>
                    </Link>
                </Menu.Item>
                <Menu.Item> 
                    <Link to="/parametres">
                        <Icon name='setting' size='large'/>
                    </Link>
                </Menu.Item>
            </Menu>
        );
    }
}

export { Footer }