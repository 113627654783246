// @flow
import React, { Component, Fragment } from "react";
import { Segment, FormGroup, Button, Message } from "semantic-ui-react";
import { Form, Input } from "semantic-ui-react-form-validator";
import { messagesForm, messagesUserFirstConnection , passwordMatchRegExp} from "constantes";
import { isEmptyObject, setMessage } from "tools";

import "./FirstConnectionUserForm.scss";

type Props = {
    consumers: any
};

type State = {
    awaiting: boolean,
    firstName: string,
    lastName: string,
    password: string,
    passwordConfirmation: string,
    message: any
};

/**
 * FirstConnectionUserForm
 * @author Hugo hle@deltacode.fr
 * @memberof Forms
 * @extends {React.Component}
 * @description First Connection form
 */
class FirstConnectionUserForm extends Component<Props, State> {
  
    state = {
        awaiting: false,
        firstName: "",
        lastName: "",
        password: "",
        passwordConfirmation: "",
        message: {},
    };

    componentDidMount() {
        /**
         * @instance
         * @async
         * @memberof Forms.FirstConnectionUserForm
         * @method componentDidMount
         * @return { Void } 
        */
        Form.addValidationRule('same', (value, field) => {
            const expectedValue = this.state[field];
            return value === expectedValue;
        })
    }

    onInputChange = (event:SyntheticInputEvent<>, { name, value }:any) => {
        /**
         * @instance
         * @memberof Forms.FirstConnectionUserForm
         * @method handleInputChange
         * @params { Object } event Event
         * @params { String } name Form name
         * @params { String } name Form value
         * @return { Void } 
        */
        this.setState({ [name]: value });
    };

    onInfoSubmit = async () => {
        /**
         * @instance
         * @async
         * @memberof Forms.FirstConnectionUserForm
         * @method onInfoSubmit
         * @return { Void } 
        */
        await this.setState({ awaiting: true});
        try {
            const { connection } = this.props.consumers;
            await connection.userFirstConnection({
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                password: this.state.password,
            });
                
        } catch(error) {  
            await this.setState({ 
                awaiting: false,
                message: setMessage(messagesUserFirstConnection, error.status)
            });
        }
    };

    render() {
        /**
         * @instance
         * @method render
         * @memberof Forms.FirstConnectionUserForm
         * @return { String } JSX 
         */
        const { 
            awaiting,
            firstName, 
            lastName, 
            password, 
            passwordConfirmation, 
            message 
        } = this.state;
            return (
                <Fragment>
                { !isEmptyObject(message) && <Message color={message.color} content={message.text}/> }
                    <Form className="ui form FirstConnectionUserForm" onSubmit={this.onInfoSubmit}>
                        <FormGroup inline>
                            <Input
                                label="Prénom"
                                placeholder="Prénom"
                                width={8}
                                name="firstName"
                                value={firstName}
                                validators={["required"]}
                                errorMessages={[messagesForm.required]}
                                onChange={this.onInputChange}
                                className="color-gray"
                            />
                            <Input
                                label="Nom"
                                placeholder="Nom"
                                width={8}
                                name="lastName"
                                value={lastName}
                                validators={["required"]}
                                errorMessages={[messagesForm.required]}
                                onChange={this.onInputChange}
                                className="color-gray"
                            />
                        </FormGroup>
                        <FormGroup inline>
                            <Input
                                fluid
                                label="Nouveau mot de passe"
                                placeholder="Nouveau mot de passe"
                                width={8}
                                name="password"
                                type="password"
                                value={password}
                                validators={[
                                    "required",
                                    passwordMatchRegExp
                                ]}
                                errorMessages={[
                                    messagesForm.required, 
                                    messagesForm.isPassword
                                ]}
                                onChange={this.onInputChange}
                            />
                            <Input
                                fluid
                                label="Confirmez votre mot de passe"
                                placeholder="Confirmez votre mot de passe"
                                width={8}
                                name="passwordConfirmation"
                                type="password"
                                value={passwordConfirmation}
                                validators={[
                                    "required", 
                                    "same:password", 
                                    passwordMatchRegExp
                                ]}
                                errorMessages={[
                                    messagesForm.required,
                                    messagesForm.matchPassword,
                                    messagesForm.isPassword
                                ]}
                                onChange={this.onInputChange}
                            />
                        </FormGroup>
                        <Segment basic textAlign="center">
                        <Button
                            loading={awaiting}
                            disabled={awaiting}
                            type="submit"
                            content=" Mettre à jour"
                            className="btn default"
                            size="large"
                        />
                        </Segment>
                    </Form>
                </Fragment>
            );
    }
}

export { FirstConnectionUserForm };