// @flow
import { LocalStorage } from './storageService';
import { checkStatus, checkError } from 'tools';

/**
 * Metadata service
 * @author Tomasz tpr@deltacode.fr
 * @memberof Services
 * @description User metadata service
 */
class Metadata {

    static url:any = process.env.REACT_APP_API_URL;
    static entrypoints:any = {
        metadata : 'metadata'
    }

    static get = async (metaKey:string) => {
        /**
         * Get metadata
         *
         * @async
         * @method get
         * @memberof Services.Metadata
         * @param metaKey Metakey to get
         * @return { Object } User metadata
        */
        try {
            const token = await LocalStorage.getItem('token') || '{}';
            const response = await fetch(`${this.url}/${this.entrypoints.metadata}/metakey/${metaKey}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                mode: 'cors'
            });
            return checkStatus(response);
        } catch(error) {
            throw checkError(error);
        }
    }

    static put = async (data:any, metaKey:string) => {
        /**
         * Put metadata
         *
         * @async
         * @method put
         * @memberof Services.Metadata
         * @param data data for update
         * @param metaKey Metadata to update
         * @return { String } Updated metadata message
        */
        try {
            const token = await LocalStorage.getItem('token') || '{}';
            const value = { metaValue : data.metaValue, metaKey}
            const response = await fetch(`${this.url}/${this.entrypoints.metadata}/${data.mid}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify(value),
                mode: 'cors'
            });
            return checkStatus(response);
        } catch(error) {
            throw checkError(error);
        }
    }

    static post = async (data:any, metaKey:string) => {
        /**
         * Post metadata
         *
         * @async
         * @method post
         * @memberof Services.Metadata
         * @param data data to create
         * @param metaKey Metakey to post
         * @return { String } Created metadata message
        */
        try {
            const token = await LocalStorage.getItem('token') || '{}';
            const value = { metaValue : data, metaKey: metaKey}
            const response = await fetch(`${this.url}/${this.entrypoints.metadata}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify(value),
                mode: 'cors'
            });
            return checkStatus(response);
        } catch(error) {
            throw checkError(error);
        }
    }
}
export  { Metadata };