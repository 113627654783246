// @flow
import React, { Component } from "react";
import { Loading } from "components";
import { logo } from "constantes";
import { Grid, Image } from "semantic-ui-react";

type Props = {
    match: any,
    history: any,
    consumers: any
};

type State = {
    loading: boolean
}

/**
 * Authentication
 * @author Romain rsa@deltacode.fr
 * @memberof Containers
 * @extends {React.Component}
 * @description Authentication container
 */

class Authentication extends Component<Props, State> {

    constructor(props: Props) {
        /**
         * @constructor
         */
        super(props)
        this.state = {
            loading: true
        }
    }

    async componentDidMount() {
        /**
         * @instance
         * @async
         * @memberof Containers.Authenticate
         * @method componentDidMount
         * @return { Void } 
         */
        try {
            const { connection } = this.props.consumers;
            await connection.authenticate(this.props.match.params.cid);
            await this.setState({
                loading: true,
            });
        }
        catch(error) {  
            this.props.history.push('/connexion');
        }
    }

    render() {
        /**
         * @instance
         * @method render
         * @memberof Containers.Activate
         * @return { String } JSX 
         */
        const {loading } = this.state;
        return (
            <Grid className="Activate login" centered padded columns={1}>
                <Grid.Column mobile={14} tablet={8} computer={6}>
                    <Image
                        style={{ marginTop: "1em" }}
                        src={logo}
                        size="tiny"
                        centered
                    />
                    {loading && <Loading title="Veuillez patienter" />}
                </Grid.Column>
            </Grid>
        );
   }
}

export { Authentication };